import styled from 'styled-components';

export const CardAnuncianteContainer = styled.div`
    width: 350px;

    display: flex;
    flex-direction: row;
    align-items: center;

    @media screen and (max-width: 768px){
        width: 100%;
    }
`;

export const CardAnuncianteImage = styled.div`
    width: 90px;
    height: 90px;

    margin-right: 15px;

    border-radius: 50%;

    overflow: hidden;
    text-align: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    img{
        width: auto;
        height: 100%;
        object-fit: cover;
    }

    @media screen and (max-width: 768px){
        width: 70px;
        height: 70px;
    }
`; 

export const CardAnuncianteInfoContainer = styled.div`
    width: calc(100% - 90px);
`;

export const Title = styled.p`
    font-size: 18px;
    font-weight: bold;

    margin-top: 0;
    margin-bottom: 10px;

    @media screen and (max-width: 768px){
        font-size: 16px;
    }
`;

export const Subtitle = styled.p`
    font-size: 16px;

    margin-top: 0;
    margin-bottom: 0;

    @media screen and (max-width: 768px){
        font-size: 14px;
    }
`;