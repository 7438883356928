import styled from 'styled-components';

export const HomeContainer = styled.div`
    width: 100%;

    @media screen and (max-width: 768px){
        margin-top: 60px;
    }
`;

export const HomeSearch = styled.div`
    width: 100%;

    background-color: #000;

    display: flex;
    flex-direction: row;
`;

export const HomeResults = styled.div`
    width: calc(100% - 300px);

    display: flex;
    flex-direction: column;

    @media screen and (max-width: 1250px){
        width: 100%;
        padding: 0 0 0 10px;
    }

    @media screen and (max-width: 960px){
        padding: 0;
    }

    .infinite-scroll-component {
        overflow: initial !important;
    }
`;

export const Content = styled.div`
    width: 100%;
    max-width: 1220px;

    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;

    padding: 0 10px;

    margin-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
`;