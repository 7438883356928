import React from 'react';

import { LoadingContainer, LoadingImage, LoadingText } from './loading-styled';

// Image
import LogoClassi from './../../../resources/imgs/classi_feirao.png';
import LoadingSvg from './loading.svg';

const Loading = () => {

    return(
        <LoadingContainer>
            <LoadingSvg />
            {/* <LoadingText>Loading</LoadingText> */}
        </LoadingContainer>
    )

}

export default Loading;