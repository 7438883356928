import React, { useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import { ModalContainer, ModalPaper, ModalTitle, ModalContent, ModalClose } from './modal-styled';

// Icons
import CloseIcon from '@material-ui/icons/Close';

const Modal = ({ titulo, children }, ref) => {
    const [openModal, setOpenModal] = useState(false);

    useImperativeHandle(ref, () => ({
        toggleModal() {
            setOpenModal(!openModal)
        }
    }))

    return (
        <ModalContainer
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            open={openModal}
        >
            <ModalPaper>
                <ModalClose aria-label="fechar" onClick={() => setOpenModal(false)}>
                    <CloseIcon />
                </ModalClose>
                <ModalTitle>{titulo}</ModalTitle>
                <ModalContent>
                    {children}
                </ModalContent>
            </ModalPaper>
        </ModalContainer>
    )

}

export default forwardRef(Modal);