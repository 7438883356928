import React from 'react';
import Topbar from '../../components/topbar/topbar';
import { InativoContainer, InativoImage } from './Inativo-styled';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { Hidden } from '@material-ui/core';
import { fetchLayout } from './../../actions';

const Inativo = () => {
    const dispatch = useDispatch();
    const layout = useSelector(state => state.layout.layoutInativo);

    useEffect(() => {
        dispatch(fetchLayout());
    }, []);

    return(
        <>
            <Topbar hideSearch/>
            
            {layout &&
                <InativoContainer>
                    <Hidden smDown>
                        <InativoImage href={layout.feirao_intaivo_url_redirect} src={layout.feirao_inativo_img_desktop} />
                    </Hidden>

                    <Hidden smUp>
                        <InativoImage href={layout.feirao_intaivo_url_redirect} src={layout.feirao_inativo_img_mobile} />
                    </Hidden>
                </InativoContainer>
            }
        </>
    )
}

export default Inativo;