import React, {useState, useEffect, useRef} from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { AnuncioSlider, AnuncioSimpleText, AnuncioInfo, AnuncioContato, AnuncioContatoBtns, ContatoContainer, PriceContainer, PriceTag, AnuncioContainer, AnuncioImgContainer, AnuncioImgContent, AnuncioTitleContainer, AnuncioTitle, AnuncioSubtitle, AnuncioList, AnuncioListItem, AnuncioLoadingContainer } from './Anuncios-styled';
import Topbar from '../../components/topbar/topbar';
import Gallery from '../../components/gallery/gallery';
import Currency from 'react-currency-formatter';
import { CardPriceOld } from '../../components/card/card-styled';
import { ContatoBtn } from '../../components/contatos/contatos-styled';
import { SvgIcon } from '@material-ui/core';
import { Hidden } from '@material-ui/core';
import AnuncioSeparator from './../../components/anuncio-separator/anuncio-separator';
import Loading from './../../components/loading/loading';
import ReactGA from 'react-ga';

import Slider from "react-slick";

import Modal from './../../components/modal/modal';
import { ModalList, ModalItemList, ModalOptions, ModalBtn } from './../../components/modal/modal-styled';

// Icons
import AddIcCallIcon from '@material-ui/icons/AddIcCall';
import WhatsappIcon from './../../../resources/svg/whatsapp.svg';
import RoomIcon from '@material-ui/icons/Room';
import PhoneIcon from '@material-ui/icons/Phone';
import ArrowBackIosOutlinedIcon from '@material-ui/icons/ArrowBackIosOutlined';
import ArrowForwardIosOutlinedIcon from '@material-ui/icons/ArrowForwardIosOutlined';
import Resumo from '../../components/resumo/resumo';
import CardAnunciante from '../../components/card-anunciante/cardAnunciante';
import { FabBtn, FloatBtn, FabContainer, FabOverlay } from '../../components/anunciante-info/anunciante-info-styled';
import SuggestCard from '../../components/suggest-card/SuggestCard';
import { fetchAnuncioById, fetchAnunciosSimilares, fetchLayout } from '../../actions';
import { useDispatch, useSelector } from 'react-redux';
import { getIdFromURL } from '../../../helpers/stringHelper';
import { FETCH_ANUNCIANTE_INFO } from '../../actions/types';
import { initAnalytics, sendAnalytics, enviarPageView } from '../../components/analytics-gjc/analytics-gjc';
import { analyticsEvent } from '../../components/analytics-gjc/analytics-events';
import Formulario from '../../components/formulario/formulario';

const vitrineType = process.env.VITRINE_TYPE.toLowerCase();

const PhoneList = ({ phoneList }) => {
  return (
      <ModalList>
        {phoneList && phoneList.map(item =>
          <ModalItemList key={`key_${item.id}`}>
            <a href={`tel:+${item.numero}`}>
              {`${item.operadora ? item.operadora.descricao + ' - ' : ''} ${item.numeroFormatado}`}
            </a>
          </ModalItemList>
          )}
      </ModalList>
  )
};

const MapAlert = ({ modalRef, to }) => {
  return (
      <ModalOptions>
          <ModalBtn variant="contained" onClick={() => modalRef.current.toggleModal()}>Não</ModalBtn>
          <ModalBtn variant="contained" component="a" target="_blank" href={`https://www.google.com/maps/search/?api=1&query=${to ||  ''}`} onClick={() => modalRef.current.toggleModal()}>Sim</ModalBtn>
      </ModalOptions>
  );
}

const settingsCarrossel = {
  centerPadding: "60px",
  speed: 500,
  arrows: true,
  draggable: false,
  dots: true,
  lazyLoad: true,
  responsive: [
      {
          breakpoint: 2500,
          settings: {
              slidesToScroll: 4,
              slidesToShow: 4,
              infinite: true,
          },
      },
      {
          breakpoint: 768,
          settings: {
              slidesToScroll: 2,
              slidesToShow: 2,
              infinite: true,
          },
      }
  ],
  nextArrow: <ArrowForwardIosOutlinedIcon />,
  prevArrow: <ArrowBackIosOutlinedIcon />
};

const Anuncio = () => {
  const params = useParams();
  const location = useLocation();
  const dispatch = useDispatch();
  const modalRef = useRef();

  // Limpa dados do anunciante antes de carregar a pagina
  dispatch({type: FETCH_ANUNCIANTE_INFO, payload: null});

  // Carrega os dados da store
  const idFeirao = useSelector(state => state.layout.idFeirao);
  const anuncio = useSelector(state => state.anuncio.anuncio);
  const relacionados = useSelector(state => state.anuncio.relacionados);

  // Variaveis da pagina
  const [loading, setLoading] = useState(true);
  const [floatBtn, setFloatBtn] = useState('');
  const [modalTitle, setModalTitle] = useState('');
  const [modalContent, setModalContent]  = useState('');
  const [anuncianteInfo, setAnuncianteInfo] = useState('');

  useEffect(() => {

    if(!anuncio){
      dispatch(fetchAnuncioById({ anuncioId: getIdFromURL(params.id) }));
    } else if(anuncio.id !== getIdFromURL(params.id)){
      dispatch(fetchAnuncioById({ anuncioId: getIdFromURL(params.id) }));
    }

    dispatch(fetchAnunciosSimilares(getIdFromURL(params.id)));

    setLoading(true);

  }, []);

  useEffect(() => {

    if(anuncio && anuncio.id){
      const { parceiro } = anuncio;
      setAnuncianteInfo(parceiro);
    }

    const idFromUrl = getIdFromURL(params.id);

    if(anuncio && anuncio.id == idFromUrl){

      setLoading(false);

      //  Analytics
      initAnalytics(getIdFromURL(params.id), process.env.SITEID_ANALYTICS);
      enviarPageView({ idFeirao: idFeirao, idContrato: anuncio.parceiro.id, idAnuncio: anuncio.id });

      ReactGA.initialize(`${process.env.CODIGO_ANALYTICS}`);
      ReactGA.pageview(`${vitrineType + window.location.pathname}`);

    }
    
  }, [anuncio]);

  useEffect(() => {
    setLoading(true);
    dispatch(fetchAnuncioById({ anuncioId: getIdFromURL(params.id) }));
  }, [params]);

    return (
        <>
          <Helmet>
            <link rel="stylesheet" type="text/css" href="//cdn.jsdelivr.net/gh/kenwheeler/slick@1.8.1/slick/slick.css" />
            <link rel="stylesheet" type="text/css" href="//cdn.jsdelivr.net/gh/kenwheeler/slick@1.8.1/slick/slick-theme.css" />
          </Helmet>

            <Topbar redirectOnSearch/>

            {!loading && 
              <AnuncioContainer>
                  {/* Section da imagem do anuncio */}
                  <AnuncioImgContainer>
                      <AnuncioImgContent>
                          {anuncio && anuncio.imagens &&
                            <Gallery imageList={anuncio.imagens}/>
                          }
                          <AnuncioTitleContainer>
                            <AnuncioTitle>{anuncio.titulo}</AnuncioTitle>
                            <AnuncioSubtitle>
                            {anuncio.veiculoVersao && anuncio.veiculoVersao.descricao}
                            </AnuncioSubtitle>

                            <PriceContainer>

                              <CardPriceOld>
                                <Currency quantity={anuncio.valor} currency="BRL" />
                              </CardPriceOld>

                              {anuncio.valorPromocional && 
                                <PriceTag>
                                  <span>
                                    <Currency quantity={anuncio.valorPromocional} currency="BRL" />
                                  </span>
                                </PriceTag>
                              }

                              {!anuncio.valorPromocional && 
                                <PriceTag fontSize="24px">
                                  <span>Ligue e confira</span>                                    
                                </PriceTag>
                              }

                            </PriceContainer>

                            <Hidden smDown>
                              {anuncianteInfo && anuncianteInfo.whatsapp &&
                                <ContatoContainer>

                                  <ContatoBtn 
                                    className="whatsapp"
                                    component="a" 
                                    href={`https://wa.me/55${anuncianteInfo.whatsapp}?text=Olá, vi seu anúncio no FEIRÃO DIGITAL DE VEÍCULOS CLASSI! Quero saber mais sobre o anúncio ${process.env.BASE_URL}${location.pathname}`}
                                    target="_blank"
                                    variant="contained"
                                    onClick={() => {
                                      sendAnalytics(analyticsEvent.VER_WHATSAPP, { idAnuncio: getIdFromURL(params.id), idFeirao: idFeirao, idContrato: anuncianteInfo.id});
                                    }}
                                    startIcon={<SvgIcon className="icon" viewBox="0 0 512 512" component={WhatsappIcon} />}
                                  >
                                    Enviar mensagem
                                  </ContatoBtn>

                                </ContatoContainer>
                              }
                            </Hidden>

                          </AnuncioTitleContainer>

                      </AnuncioImgContent>

                  </AnuncioImgContainer>
              
                  {/* Section das caracteristicas */}
                  <AnuncioInfo>
                    
                    <Resumo info={anuncio}/>

                    <AnuncioContato>
                      {anuncianteInfo &&
                        <CardAnunciante  info={anuncianteInfo} nome={anuncianteInfo.nome_fantasia} marca={anuncianteInfo.url_logo}/>
                      }

                      <Hidden smDown>
                        <AnuncioContatoBtns>
                          {anuncianteInfo && anuncianteInfo.telefones &&
                            <ContatoBtn
                              className="phone"
                              variant="contained"
                              startIcon={<PhoneIcon className="icon" />}
                              onClick={() => { 
                                modalRef.current.toggleModal(); 
                                setModalTitle('Clique no número para ligar'); 
                                setModalContent('phone');
                                sendAnalytics(analyticsEvent.VER_TELEFONE, { idAnuncio: getIdFromURL(params.id), idFeirao: idFeirao, idContrato: anuncianteInfo.id });
                              }}
                            >Ligar para loja</ContatoBtn>
                          }

                          {anuncianteInfo && (anuncianteInfo.cep || anuncianteInfo.cidade || anuncianteInfo.rua) &&                    
                            <ContatoBtn
                                className="map"
                                onClick={() => { 
                                  modalRef.current.toggleModal(); 
                                  setModalTitle('Deseja abrir o mapa em uma nova aba?'); 
                                  setModalContent('map');
                                  sendAnalytics(analyticsEvent.VER_ENDERECO, { idAnuncio: getIdFromURL(params.id), idFeirao: idFeirao, idContrato: anuncianteInfo.id });
                                }}
                                variant="contained"
                                startIcon={<RoomIcon className="icon" />}
                            >
                                Ver endereço
                            </ContatoBtn>
                          }
                        </AnuncioContatoBtns>
                      </Hidden>
                    </AnuncioContato>
                  
                    {/* Observação */}
                    {anuncio.descricao &&
                      <>
                        <AnuncioSeparator titulo="Observação do vendedor"/>
                        <AnuncioSimpleText>
                          {anuncio.descricao}
                        </AnuncioSimpleText>
                      </>
                    }

                    {/* Detalhes */}
                    {anuncio.caracteristicas && anuncio.caracteristicas.length > 0 &&
                      <>
                        <AnuncioSeparator titulo="Detalhes"/>
                        <AnuncioList>
                          {anuncio.caracteristicas.map(item => 
                            <AnuncioListItem key={`carac_${item.id}`}>{item.descricao}</AnuncioListItem>
                          )}
                        </AnuncioList>
                      </>
                    }

                    {/* Outros detalhes */}
                    {(anuncio.qtdPortas || anuncio.finalPlaca) &&
                      <>
                        <AnuncioSeparator titulo="Outros Detalhes"/>
                        <AnuncioList>
                          {Boolean(anuncio.finalPlaca) && <AnuncioListItem>{`Final da placa: ${anuncio.finalPlaca}`}</AnuncioListItem>}
                          {Boolean(anuncio.qtdPortas) && <AnuncioListItem>{`Quantidade de portas: ${anuncio.qtdPortas}`}</AnuncioListItem>}
                        </AnuncioList>
                      </>
                    }

                    {/* Formulário - Leads  */}
                    <Formulario />
                    <br />

                    {/* Similares */}
                    {relacionados &&
                      <>
                        <AnuncioSeparator titulo="Anúncios Similares"/>
                        <AnuncioSlider>
                          <Slider className="slider" {...settingsCarrossel}>
                            {relacionados.map(item => {
                                return <SuggestCard key={`sug_${item.id}`} anuncio={item} />
                              })
                            }                        
                          </Slider>
                        </AnuncioSlider>
                      </>
                    }
                  </AnuncioInfo>

                  <Hidden smUp>
                    <FabContainer>
                      {floatBtn && 
                        <FabOverlay onClick={() => setFloatBtn(false)} />
                      }
                      
                      {anuncianteInfo && anuncianteInfo.whatsapp && 
                        <FloatBtn className="whatsapp" 
                          in={floatBtn.toString()} 
                          component="a" 
                          target="_blank" 
                          href={`https://wa.me/55${anuncianteInfo.whatsapp}?text=Olá, vi seu anúncio no FEIRÃO DIGITAL DE VEÍCULOS CLASSI! Quero saber mais sobre o anúncio ${process.env.BASE_URL}${location.pathname}`}
                          onClick={() => {
                            sendAnalytics(analyticsEvent.VER_WHATSAPP, { idAnuncio: getIdFromURL(params.id), idFeirao: idFeirao, idContrato: anuncianteInfo.id});
                          }}>
                            <SvgIcon className="icon" viewBox="0 0 512 512" component={WhatsappIcon} />
                        </FloatBtn>
                      }

                      {anuncianteInfo && anuncianteInfo.telefones &&
                        <FloatBtn className="phone" 
                          in={floatBtn.toString()} 
                          onClick={() => { 
                            modalRef.current.toggleModal(); 
                            setModalTitle('Clique no número para ligar'); 
                            setModalContent('phone'); 
                            sendAnalytics(analyticsEvent.VER_TELEFONE, { idAnuncio: getIdFromURL(params.id), idFeirao: idFeirao, idContrato: anuncianteInfo.id });
                            }}>
                            <PhoneIcon className="icon" />
                        </FloatBtn>
                      }

                      {anuncianteInfo && (anuncianteInfo.cep || anuncianteInfo.cidade || anuncianteInfo.rua) &&
                        <FloatBtn className="map" 
                          in={floatBtn.toString()} 
                          onClick={() => { 
                            modalRef.current.toggleModal(); 
                            setModalTitle('Deseja abrir o mapa em uma nova aba?'); 
                            setModalContent('map'); 
                            sendAnalytics(analyticsEvent.VER_ENDERECO, { idAnuncio: getIdFromURL(params.id), idFeirao: idFeirao, idContrato: anuncianteInfo.id });
                            }}>
                          <RoomIcon className="icon" />
                        </FloatBtn>
                      }

                      <FabBtn aria-label="Contatos" onClick={() => setFloatBtn(!floatBtn)} >
                        <AddIcCallIcon className="material-icon"/>
                      </FabBtn>
                    </FabContainer>
                  </Hidden>
              </AnuncioContainer>
            }

            {loading &&
              <AnuncioLoadingContainer>
                <Loading />
              </AnuncioLoadingContainer>
            } 

            <Modal ref={modalRef} titulo={modalTitle}>
                {modalContent === 'map' && <MapAlert modalRef={modalRef} to={`${anuncianteInfo.nome_fantasia + ',' || ''} ${anuncianteInfo.cep ? anuncianteInfo.cep.slice(0, 5) : ''}-${anuncianteInfo.cep ? anuncianteInfo.cep.slice(-3) : ''}`}/>}
                {modalContent === 'phone' && <PhoneList modalRef={modalRef} phoneList={anuncianteInfo.telefones} />}
            </Modal>
        </>
    )
};

export const loadData = ({ store, match }) => {
  return Promise.all([
    store.dispatch(fetchAnuncioById({anuncioId: getIdFromURL(match.params.id)})),
    store.dispatch(fetchLayout())
  ])
}

export default Anuncio;