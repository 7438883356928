import { FETCH_LAYOUT, FETCH_LAYOUT_INATIVO, FETCH_FEIRAO_ID } from '../actions/types';

const INITIAL_STATE = {
    layout: {},
    layoutInativo: {},
    idFeirao: null
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case FETCH_FEIRAO_ID:
            return { ...state, idFeirao: action.payload };
        case FETCH_LAYOUT:
            return { ...state, layout: action.payload };
        case FETCH_LAYOUT_INATIVO:
            return { ...state, layoutInativo: action.payload };
        default:
            return state;
    }
}