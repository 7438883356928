import React from 'react';
import { CardSuggestPaper, PreviewImg, CardSuggestTitulo, CardSuggestValor, CardSuggestSubtitulo } from './SuggestCard-styled';
import { withRouter } from 'react-router';
import Currency from 'react-currency-formatter';
import { buildUrl } from '../../components/image-render/image-render';

const SuggestCard = ({ anuncio }) => {
    const {
        imagens,
        titulo,
        valor,
        id, 
        localizacao,
        favorito,
        url
    } = anuncio;

    return(
        <CardSuggestPaper elevation={3}>

            <PreviewImg
                to={{ pathname: url ? url : null }}
                src={ imagens && imagens.length > 0 ? buildUrl({src: imagens[0].imageUrl, width: 200, height: 200, resizeType:'fill'}): null }
                target="_self"
            />

            <CardSuggestTitulo to={{ pathname: url ? url : null }} replace={true} target="_self">
                {titulo}
            </CardSuggestTitulo>

            <CardSuggestSubtitulo to={{ pathname: url ? url : null }} replace={true} target="_self">
                {localizacao && 
                    `${localizacao.cidade} - ${localizacao.estado}`
                }
            </CardSuggestSubtitulo>

            <CardSuggestValor>
                {valor && 
                    <Currency
                        quantity={valor}
                        currency="BRL"
                    />
                }
                {!valor && 'Valor a combinar'}
            </CardSuggestValor>

        </CardSuggestPaper>
    )
}

export default withRouter(SuggestCard);