import styled from 'styled-components';

export const LoadingContainer = styled.div`
    width: ${props => props.fullWidth ? '100%' : 'inherit'};
    display: flex;
    flex-direction: column;

    margin-left: auto;
    margin-right: auto;

    .loader{
        margin: 0 0 2em;
        height: 100px;
        width: 20%;
        text-align: center;
        padding: 1em;
        margin: 0 auto 1em;
        display: inline-block;
        vertical-align: top;
    }

    svg{
        margin-left: auto;
        margin-right: auto;
        transform: scale(2, 3);
    }

    svg path, svg rect {
        fill: #f96302;
    }
`;

export const LoadingImage = styled.img`
    width: 100px;
`;

export const LoadingText = styled.p`
    color: #FFF;
`;