import { FETCH_ANUNCIANTE_LAYOUT, FETCH_ANUNCIANTE_INFO } from '../actions/types';

const INITIAL_STATE = {
    anuncianteLayout: {},
    anuncianteInfo: {}
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case FETCH_ANUNCIANTE_LAYOUT:
            return { ...state, anuncianteLayout: action.payload };
        case FETCH_ANUNCIANTE_INFO:
            return { ...state, anuncianteInfo: action.payload };
        default:
            return state;
    }
}