import React from 'react';

export const getIdFromURL = (url) => {
    const stringSplit = url.split('-');
    const id = stringSplit[stringSplit.length - 1];
    return id;
}

export const veiculoResultsText = (search, filter) => {
    let searchText;

    if(search.marca && filter.marcas){
        searchText = `${filter.marcas.filter(item => item.valor === search.marca)[0].descricao || ''}`
    }

    if(search.modelo && filter.modelos){
        const modelo = filter.modelos.filter(item => item.valor === search.modelo);

        if(modelo && modelo.length > 0) {
            searchText += ` ${filter.modelos.filter(item => item.valor === search.modelo)[0].descricao || ''}`;
        }
    }

    return <p><strong>{filter.qtdTotalRegistros}</strong> resultados {searchText ? `para ${searchText}` : ''}</p>
}