import styled, { keyframes, css } from 'styled-components';
import { Button, Fab, darken, IconButton } from '@material-ui/core';

export const AnuncianteInfoContainer = styled.div`
    width: 100%;
    background-color: #000;

    padding: 20px;

    display: flex;

    position: relative;
    z-index: 9;
`;

export const Content = styled.div`
    width: 100%;
    max-width: 1100px;

    display: flex;
    flex-direction: row;
    justify-content: space-between;

    margin-left: auto;
    margin-right: auto;

    @media screen and (max-width: 768px){
        flex-direction: column;
        align-items: center;
    }
`;

export const AnuncianteLogo = styled.img`
    width: 280px;
    height: 100px;

    object-fit: contain;

    @media screen and (max-width: 768px){
        margin-bottom: 10px;
    }
`;

export const FabContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    position: fixed;
    bottom: 20px;
    right: 10px;
    z-index: 99;    
`;

export const FabOverlay = styled.div`
    background-color: rgba(0, 0, 0, .7);

    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
`;

export const FloatBtn = styled(IconButton)`
    &&{
        width: 60px;
        height: 60px;
        animation: ${props => props.in === "true" ? css`${fadeIn} .2s linear` : null};
        animation: ${props => props.in === "false" ? css`${fadeOut} .2s linear` : null};
        animation-fill-mode: both;
        position: relative;
        opacity: 0;
        bottom: -20px;
        transform: scale(.5, .5);

        background-color: #FFF;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);

        margin-top: 5px;
        margin-bottom: 5px;

        &:nth-child(0){
            animation-delay: .05s;
        }

        &:nth-child(1){
            animation-delay: .1s;
        }

        &.phone .icon{ color: #3140a8; }
        &.map .icon{ color: #d80027; }

        &:hover{
            background-color: ${darken('#FFFFFF', 0.2)};
        }
    }
`;

export const FabBtn = styled(Fab)`
    &&{
        width: 70px;
        height: 70px;
        background-color: #f96302;
        color: #FFF;

        margin-top: 10px;

        &:hover{
            background-color: ${darken('#f96302', 0.2)};
        }

        .material-icon{
            width: 35px;
            height: 50px;
        }

        .phone-icon {
            width: 45%;
            height: 76%;
            position: relative;
            top: 2px;
            left: -1px;
        }
    }
`;

const fadeIn = keyframes`
    from {
        opacity: 0;
        transform: scale(.5, .5);
        bottom: -20px;
    }

    to {
        opacity: 1;
        transform: scale(1, 1);
        bottom: 0px;
    }
`;

const fadeOut = keyframes`
    from {
        opacity: 1;
        bottom: 0px;
        transform: scale(1, 1);
    }

    to {
        opacity: 0;
        bottom: -20px;
        transform: scale(.5, .5);
    }
`;