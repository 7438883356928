import React from 'react';
import { CardAnuncianteContainer, CardAnuncianteInfoContainer, CardAnuncianteImage, Title, Subtitle } from './cardAnunciante-styled';

const CardAnunciante = ({ info, nome, marca }) => {
    

    return(
        <CardAnuncianteContainer>
            <CardAnuncianteImage>
                <img src={marca} data-object-fit="cover"/>
            </CardAnuncianteImage>
            <CardAnuncianteInfoContainer>
                <Title>
                    {nome}
                </Title>

                {info && info.rua && info.cidade && info.estado &&
                    <Subtitle>
                        {`${info.rua || ''}, ${info.bairro}, 
                        ${info.cidade || ''} ${' - ' + info.estado || ''}`}
                    </Subtitle>
                }
            </CardAnuncianteInfoContainer>
        </CardAnuncianteContainer>
    );

};

export default CardAnunciante;