export const FETCH_DEV = 'fetch_dev';

// Layout
export const FETCH_LAYOUT = 'fetch_layout';
export const FETCH_LAYOUT_INATIVO = 'fetch_layout_inativo';
export const FETCH_FEIRAO_ID = 'fetch_feirao_id';

// Buscas
export const FETCH_BUSCA = 'fetch_busca';
export const FETCH_BUSCA_AUTOCOMPLETE = 'fetch_busca_autocomplete';
export const FETCH_FILTRO_VENDEDOR = 'fetch_filtro_vendedor';
export const FETCH_SEARCH_OBJECT = 'fetch_search_object';

// Anunciante
export const FETCH_ANUNCIANTE_LAYOUT = 'fetch_anunciante';
export const FETCH_ANUNCIANTE_INFO = 'fetch_anunciante_info';

// Anuncios
export const FETCH_ANUNCIO = 'fetch_anuncio';
export const FETCH_ANUNCIO_RELACIONADOS = 'fetch_anuncio_relacionados';

// Formulario
export const FETCH_FORMULARIO = "fetch_formulario";
export const SET_FORM_CONTATADO = 'set_form_contatado';