import App from './App';

// Pages
import Home, { loadData as HomeLoadData } from './pages/Home/Home';
import Anuncio, { loadData as AnuncioLoadData } from './pages/Anuncio/Anuncio';
import Anunciante, { loadData as AnuncianteLoadData } from './pages/Anunciante/Anunciante';
import Error404 from './pages/Errors/Error404';
import Busca from './pages/Busca/Busca';
import Inativo from './pages/Inativo/Inativo';

const Routes = [
    {
        component: App,
        routes: [
            {
                loadData: HomeLoadData,
                component: Home,
                path: '/',
                exact: true
            },
            {
                component: Busca,
                path: '/busca'
            },
            {
                loadData: AnuncioLoadData,
                component: Anuncio,
                path: '/anuncio/:id',
                exact: true
            },
            {
                loadData: AnuncianteLoadData,
                component: Anunciante,
                path: '/anunciante/:id'
            },
            {
                component: Inativo,
                path: '/inativo'
            },
            {
                component: Error404
            }
        ]
    }
]

export default Routes;