import { FETCH_BUSCA, FETCH_BUSCA_AUTOCOMPLETE, FETCH_FILTRO_VENDEDOR, FETCH_SEARCH_OBJECT } from '../actions/types';

const INITIAL_STATE = {
    filtros: {},
    filtroVendedor: [],
    searchObj: {},
    buscaResults: [],
    autocomplete: []
};

export default (state = INITIAL_STATE, action) => {
    switch(action.type){
        case FETCH_BUSCA:
            const { 
                anuncios, 
                categorias, 
                caracteristicas,
                marcas,
                modelos,
                versoes,
                documentacoes,
                tiposCambio,
                tiposCombustivel,
                finalPlacas,
                cores,
                ordenacao,
                paginaAtual,
                qtdPaginas,
                qtdTotalRegistros
            } = action.payload;

            let arrayAnuncios = [];

            if(paginaAtual > 0){
                arrayAnuncios = state.buscaResults;
                anuncios.map(item => arrayAnuncios.push(item));
            } else {
                arrayAnuncios = anuncios;
            }

            return { 
                ...state, 
                buscaResults: arrayAnuncios || [],
                filtros: {
                    categorias, 
                    caracteristicas,
                    marcas,
                    modelos,
                    versoes,
                    documentacoes,
                    tiposCambio,
                    tiposCombustivel,
                    finalPlacas,
                    cores,
                    ordenacao,
                    paginaAtual,
                    qtdPaginas,
                    qtdTotalRegistros
                }
            }
        case FETCH_FILTRO_VENDEDOR:
            return { ...state, filtroVendedor: action.payload }
        case FETCH_BUSCA_AUTOCOMPLETE:
            return { ...state, autocomplete: action.payload }
        case FETCH_SEARCH_OBJECT:
            return { 
                ...state, 
                searchObj: { ...state.searchObj, ...action.payload }
            }
        default:
            return state;
    }
}