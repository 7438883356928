import React from 'react';
import Currency from 'react-currency-formatter';
import { CardPriceContainer, CardPriceOld, CardPriceNew, LigueConfira } from './card-styled';

const CardPrice = ({ oldPrice, newPrice, orientation, to }) => {

    const renderValorPromocional = valorPromocional => {

        if(valorPromocional) {
            return (
                <CardPriceNew orientation={orientation}>
                    <Currency quantity={newPrice} currency="BRL" />
                </CardPriceNew>
            )
        } else {
            return <LigueConfira>Ligue e confira</LigueConfira>            
        }

    };

    return (

        <CardPriceContainer to={to} orientation={orientation}>

            <CardPriceOld orientation={orientation}>
                <Currency quantity={oldPrice} currency="BRL" />
            </CardPriceOld>
            
            {renderValorPromocional(newPrice)}

        </CardPriceContainer>
    );

}

export default CardPrice;