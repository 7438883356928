import { FETCH_FORMULARIO, SET_FORM_CONTATADO } from '../actions/types';

const INITIAL_STATE = {
    formText: [],
    formColor: [],
    formFields: [],
    contatado: false
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case FETCH_FORMULARIO:
            const { 
                leads_titulo, 
                leads_subtitulo,
                leads_btn_text,
                leads_texto_juridico,
                leads_texto_agradecimento,
                cor_fundo_leads,
                cor_texto_leads,
                cor_botao_leads,
                cor_texto_botao_leads,
                campos_lead
            } = action.payload;

            return { 
                ...state, 
                formText: {
                    titulo: leads_titulo,
                    subtitulo: leads_subtitulo,
                    juridico: leads_texto_juridico,
                    agradecimento: leads_texto_agradecimento,
                    btnText: leads_btn_text
                },
                formColor: {
                    bgColor: cor_fundo_leads,
                    textColor: cor_texto_leads,
                    btnColor: cor_botao_leads,
                    btnTextColor: cor_texto_botao_leads
                },
                formFields: campos_lead
            };
        case SET_FORM_CONTATADO:
            return { ...state, contatado: true }
        default:
            return state;
    }
}