import React from 'react';
import { Helmet } from 'react-helmet';
import { ErrorContainer, ErrorTitle, ErrorText, ClassiLogo } from './Error404-styled';

import Topbar from '../../components/topbar/topbar';

// Images
import ClassiImg from './../../../resources/imgs/classi_feirao.png';

const Error404 = () => {
    return (
        <>           
            <Topbar hideSearch/>

            <ErrorContainer>
                <ClassiLogo src={ClassiImg}/>
                <ErrorTitle>OPS!</ErrorTitle>
                <ErrorText>
                    Página não encontrada
                </ErrorText>
            </ErrorContainer>
        </>
    )
}

export default Error404;