import styled from 'styled-components';

export const BannerContainer = styled.div`
    width: 100%;

    display: block;
    line-height: 0;
`;

export const BannerImg = styled.img`
    width: 100%;
`;