import React from 'react';
import { Separator } from './anuncio-separator-styled';

const AnuncioSeparator = ({titulo}) => {
    return(
        <Separator>
            <span>{titulo}</span>
        </Separator>
    )
};

export default AnuncioSeparator;