import React from 'react';
import InputMask from 'react-input-mask';
import NumberFormat from 'react-number-format';

export const PhoneMask = (props) => {
    return <InputMask mask="(99) 99999-9999" name={props.name} onChange={props.onChange} />
};

export const CPFMask = (props) => {
    return <InputMask mask="999.999.999-99" name={props.name} onChange={props.onChange} />
};

export const CurrencyMask = (props) => {
    return <NumberFormat name={props.name} onChange={props.onChange} prefix={'R$ '} renderText={value => <span>{value}</span>}/>
}