import React, { useState, useEffect } from 'react';
import { FilterSelect } from '../filter/filter-styled';
import { InputLabel, Select, MenuItem } from '@material-ui/core';
import { TagList, TagItem, TagRemove } from './TagSelect-styled';

// Icons
import ClearOutlinedIcon from '@material-ui/icons/ClearOutlined';

const TagSelect = ({options, onChange}) => {

    const [selectedList, setSelectedList] = useState([]);
    const [itemList, setItemList] = useState(options);
    const preventSelect = null;

    const handleSelect = (value, removeThis) => {
        let newSelectedList = selectedList;

        if(removeThis){
            newSelectedList.push(itemList.filter(item => item.valor === value)[0]);
            const newItemList = itemList.filter(item => item.valor !== value);

            setItemList(newItemList);
        } else {
            const newItemList = itemList;
            newItemList.push(value);
            newSelectedList = selectedList.filter(item => item !== value);

            setItemList(newItemList);
        }

        setSelectedList(newSelectedList);
        const arrayValue = newSelectedList.map(item => item.valor);
        onChange(arrayValue);
    }

    useEffect(() => {
        setItemList(options);
    }, [options]);

    return(
        <FilterSelect variant="outlined">

            <InputLabel shrink={false}>Opcionais</InputLabel>
            
            <Select className="select" name="caracteristicas" value={preventSelect} onChange={(e) => handleSelect( e.target.value, true)}>
                {itemList && itemList.map((item, index) => {
                        if(!item.valor)
                            alert(item);
                        return <MenuItem key={`caracteristicas-${index}`} value={item.valor}>{item.descricao}</MenuItem>
                    })
                }                
            </Select>

            <TagList>
                {selectedList && selectedList.map((item, index) => {        
                        return (
                            <TagItem key={`tag-${index}`} onClick={() => handleSelect(item, false)}>
                                {item.descricao}
                                <TagRemove><ClearOutlinedIcon /></TagRemove>
                            </TagItem>
                        )
                    })
                }
            </TagList>

        </FilterSelect>
    )
};

export default TagSelect;