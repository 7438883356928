import styled from 'styled-components';
import Highlighter from 'react-highlight-words';
import Select from 'react-select';

export const Highlight = styled(Highlighter)`
    &&{
        .highlighted-text{
            font-weight: bold;
            background-color: transparent;
        }
    }
`;

export const SearchSelect = styled(Select).attrs({
    classNamePrefix: 'select'
})`
    &&{
        width: 90%;
        max-width: 600px;

        .select_{
            &_control{
                display: flex;

                padding: 10px 20px;
                background-color: #e5e5e5;
            }

            &_menu{
                z-index: 99;
            }

            &_indicator-separator{ display: none; }

            &_dropdown-indicator{ 
                width: 30px; 
                height: 30px; 
                color: #666666; 
                padding: 0;

                svg{
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
`