import React, { useState, useEffect } from 'react';
import { FilterContainer, FilterPaper, FilterSelect } from './filter-styled';
import { Formik } from 'formik';
import FilterVeiculos, { veiculoSearch } from './filter-veiculos';
import { fetchBusca } from '../../actions';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { FETCH_SEARCH_OBJECT } from '../../actions/types';
import { MenuItem, Select, Hidden } from '@material-ui/core';

const Filter = ({callback, defaultFilter, hidePartners}) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const filtro = useSelector(state => state.busca.filtros);
    const searchObj = useSelector(state => state.busca.searchObj);

    const [initialValues, setInitialValues] = useState(veiculoSearch);
    const [oldSearchObj, setOldSearchObj] = useState();

    useEffect(() => {
        let filter = Object.assign({}, veiculoSearch);

        if(defaultFilter){
            filter = Object.assign(filter, defaultFilter);
        }

        filter = Object.assign(filter, searchObj);

        dispatch({type: FETCH_SEARCH_OBJECT, payload: filter});
    }, []);

    useEffect(() => {
        // Só chama o evento de busca, 
        // caso o objeto de busca seja diferente do buscado anteriormente
        if(searchObj && Object.keys(searchObj).length > 0 
        && searchObj !== initialValues 
        && JSON.stringify(searchObj) != JSON.stringify(oldSearchObj)
        ){
            dispatch(fetchBusca(searchObj));
            setInitialValues(searchObj);
            setOldSearchObj(searchObj);
        }
    }, [searchObj]);

    const cleanFilter = () => {
        dispatch({ type: FETCH_SEARCH_OBJECT, payload: Object.assign({}, veiculoSearch) });
    }

    const selectSort = (e) => {
        const filter = Object.assign(searchObj, {ordenacao: e.target.value});
        dispatch({type: FETCH_SEARCH_OBJECT, payload: filter});
    }

    return (
        <FilterContainer>
            {filtro && filtro.ordenacao &&
                <Hidden smDown>
                    <FilterSelect className="noMarginTop" variant="outlined">
                        <Select 
                            className="select" 
                            name="ordenacao"
                            onChange={selectSort}
                            value={searchObj.ordenacao || filtro.ordenacao[0].valor}>
                                
                            {filtro.ordenacao.map((item, index) => 
                                    <MenuItem key={'ordenacao-' + index} value={item.valor}>{item.descricao}</MenuItem>
                                )
                            }
                        </Select>
                    </FilterSelect>
                </Hidden>
            }

            <FilterPaper>
                <h3>Filtros</h3>
                <br />
                <Formik
                    initialValues={initialValues}
                    enableReinitialize 
                    onSubmit={(values, {setSubmitting}) => {
                        let newValues = Object.assign(searchObj, values);

                        dispatch({ type: FETCH_SEARCH_OBJECT, payload: newValues });

                        if(values.parceiroFeirao && !window.location.pathname.includes('anuncio')){
                            history.replace(`/anunciante/${values.parceiroFeirao}`);
                        }
                    }}
                >
                    {({handleSubmit, errors}) => (                      
                        <FilterVeiculos 
                            handleSubmit={handleSubmit} 
                            hidePartners={hidePartners} 
                            cleanFilter={cleanFilter}
                            closeTopBar={callback || null}
                        />
                    )}
                </Formik>
            </FilterPaper>
        </FilterContainer>
    )

}

export default Filter;