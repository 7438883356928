import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';

import { Highlight, SearchSelect } from './searchAutocomplete-styled';

import SearchIcon from '@material-ui/icons/Search';
import { components } from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAutocomplete, fetchBusca } from '../../actions';
import { FETCH_SEARCH_OBJECT } from '../../actions/types';

const groupStyles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
};
const groupBadgeStyles = {
    backgroundColor: '#EBECF0',
    borderRadius: '2em',
    color: '#172B4D',
    display: 'inline-block',
    fontSize: 12,
    fontWeight: 'normal',
    lineHeight: '1',
    minWidth: 1,
    padding: '0.16666666666667em 0.5em',
    textAlign: 'center',
};

const formatGroupLabel = data => (
    <div style={groupStyles}>
        <span>{data.label}</span>
        <span style={groupBadgeStyles}>{data.options.length}</span>
    </div>
)

let inputValue;

const optionRenderer = (option) => {
    return (
        <Highlight
            highlightClassName='highlighted-text'
            searchWords={[inputValue]}
            textToHighlight={option.name}
        />
    )
}

const DropdownIndicator = props => {
    return (
        <components.DropdownIndicator {...props}>
            <SearchIcon />
        </components.DropdownIndicator>
    )
}

const SearchAutocomplete = ({ callback, redirectOnSearch, ...props }) => {
    const dispatch = useDispatch();

    const autocompleteResults = useSelector(state => state.busca.autocomplete);
    const searchObj = useSelector(state => state.busca.searchObj);

    const [autocompleteList, setAutoCompleteList] = useState([]);
    const [openMenu, setOpenMenu] = useState(false);
    const [oldSearchObj, setOldSearchObj] = useState();
    const [callRedirect, setCallRedirect] = useState();

    const handleSearch = (query, {action}) => {
        if(action === "input-change"){ setOpenMenu(true) };

        if(query){
            dispatch(fetchAutocomplete(query));
        }
    }

    const search = (e) => {
        let search;

        if(e.searchKey === 'marca'){
            search = Object.assign(
                searchObj, 
                {[e.searchKey]: e.id.toString()}
            );
        } else {
            search = Object.assign(
                searchObj,
                {
                    [e.parentKey]: e.parentId.toString(),
                    [e.searchKey]: e.id.toString()
                }
            )
        }

        if(callback){
            callback();
        }

        dispatch({type: FETCH_SEARCH_OBJECT, payload: search});
    }

    useEffect(() => {
        if(autocompleteResults.length > 0){
            const categorias = autocompleteResults.map(item => ({id: item.id, name: item.name, searchKey: "marca"}));
            const subcategorias = [];

            autocompleteResults.map(({name, id, itens = []}) => itens.map(item => {
                const customItem = {
                    id: item.id,
                    parentKey: "marca",
                    parentId: id,
                    searchKey: "modelo",
                    name: `${name} - ${item.name}`,
                    items: item.items
                };

                subcategorias.push(customItem);
            }));


            const groupedList = [
                { label: 'Marcas', options: categorias },
                { label: 'Modelos', options: subcategorias }
            ]

            setAutoCompleteList(groupedList);
        }

    }, [autocompleteResults])

    return (
        <React.Fragment>
            <SearchSelect
                placeholder="Digite sua busca"
                options={autocompleteList}
                getOptionLabel={option => option.name}
                getOptionValue={option => option.id}
                formatOptionLabel={optionRenderer}
                formatGroupLabel={formatGroupLabel}
                onChange={(e) => {search(e); setOpenMenu(false); props.history.push('/');}}
                onBlur={() => setOpenMenu(false)}
                onInputChange={handleSearch}
                components={{ DropdownIndicator }}
                menuIsOpen={openMenu}
                noOptionsMessage={() => 'Não encontramos resultados para sua busca.'}
            />
        </React.Fragment>
    );
};

export default withRouter(SearchAutocomplete);