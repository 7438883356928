import styled from 'styled-components';
import { Button } from '@material-ui/core';

export const ContatoContainer = styled.div`
    width: calc(100% - 310px);

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    @media screen and (max-width: 768px){
        width: 100%;
        flex-direction: column;
    }
`;

export const ContatoBtn = styled(Button)`
    && {
        font-size: 18px;
        font-weight: bold;

        background-color: #FFF;

        padding: 10px 20px;

        &.whatsapp{color: #4caf50;}
        &.phone{ color: #3140a8; }
        &.map{ color: #d80027; }

        .MuiSvgIcon-root{
            font-size: 30px;
        }

        @media screen and (max-width: 768px){
            width: 100%;
            margin-top: 10px;
            margin-bottom: 10px;
        }
    }
`;