import styled from 'styled-components';
import { Button, TextField, FormControl, Checkbox, FormControlLabel, FormGroup } from '@material-ui/core';
import { Form } from 'formik';
import { darken } from '@material-ui/core/styles/colorManipulator';

export const FormContainer = styled.div`
    width: 100%;
    min-height: 300px;
    background-color: ${props => props.bgcolor ? props.bgcolor : '#FFF'};
    padding: 10px;

    text-align: center;
    position: relative;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

export const FormContent = styled(Form)`
    &&{
        max-width: 900px;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        align-items: center;
        
        margin-left: auto;
        margin-right: auto;
        
        .flex-display{
            width: 100%;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;

            @media screen and (max-width: 768px){
                flex-direction: column;
            }
        }

        @media screen and (max-width: 768px){
            width: 100%;
        }
    }
`;

export const FormTitle = styled.h4`
    font-size: 30px;
    font-weight: bold;
    color: ${props => props.textcolor ? props.textcolor : '#000'};

    margin-top: 30px;
    margin-bottom: 10px;
`;

export const FormSubtitle = styled.p`
    color: ${props => props.textcolor ? props.textcolor : '#000'};
`

export const FormBtn = styled(Button)`
    &&{
        font-size: 20px;
        text-transform: initial;
        color: ${props => props.btntextcolor ? props.btntextcolor : '#000'};
        background-color: ${props => props.btncolor ? props.btncolor : '#FFF'};

        min-width: 150px;

        &:hover{
            background-color: ${props => props.btncolor ? darken(props.btncolor, 0.1) : darken('#FFF', 0.1)};
        }
    }
`;

export const InputField = styled(TextField)`
    &&{
        width: calc(50% - 10px);
        margin-top: 15px;

        .MuiFormHelperText-root.Mui-error{
            color: #FFF;
        }

        .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline{
            border-color: #FFF;
        }

        input {
            width: 100%;
            font-size: 16px;
            padding: 15px 10px 10px 10px;
            border: none;
            background-color: transparent;
        }

        @media screen and (max-width: 768px){
            width: 100%;
        }
        
        .MuiOutlinedInput-root, .MuiFilledInput-root, .MuiInput-root {
            background-color: #FFF;
        }

        .MuiInputLabel-shrink {
            top: 5px !important;
        }

        .MuiInputLabel-root{
            top: -5px;
            z-index: 99;
            text-transform: uppercase;
        }
    }
`;

export const SelectField = styled(FormControl)`
    &&{
        width: calc(50% - 10px);
        margin-top: 15px;

        @media screen and (max-width: 768px){
            width: 100%;
        }

        .MuiSelect-root {
            background-color: #FFF;
        }
    }
`;

export const CheckboxGroup = styled(FormGroup)`
    &&{
        width: calc(50% - 10px);

        @media screen and (max-width: 768px){
            width: 100%;
            display: flex;
            justify-content: center;
        }
    }
`;

export const CheckboxField = styled(FormControlLabel)`
    &&&{
        .Mui-checked{
            color: #FFF;
        }

        .MuiFormControlLabel-label{
            color: #FFF;
        }

        .MuiSwitch-track{
            background-color: #FFF;
        }
    }
`;

export const FormSimpleText = styled.div`
    width: 60%;
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;

    span{
        font-size: 15px;
        color: ${props => props.color ? props.color : '#FFF'};
        text-align: center;
    }

    @media screen and (max-width: 768px){
        width: 100%;
        text-align: justify;
        padding-bottom: 10px;
    }
`;

export const FormContatado = styled.div`
    width: 100%;
    max-width: 700px;
    margin-left: auto;
    margin-right: auto;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    span{
        font-size: 36px;
        color: ${props => props.color ? props.color : '#FFF'};
    }
`;

export const FormSubmitting = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    background-color: ${props => props.bgcolor};

    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 999;
`;

export const FormSubmittingTitle = styled.p`
    font-size: 36px;
    color: ${props => props.textcolor ? props.textcolor : '#FFF'};
`;