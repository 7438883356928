import "@babel/polyfill";
import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import { createStore, compose } from 'redux';
import { Provider } from 'react-redux';
import { renderRoutes } from 'react-router-config';

import Routes from './Routes';
import reducers from './reducers';
import { createBrowserHistory } from 'history';
import { applyMiddleware } from "redux";
import thunk from "redux-thunk";
import Helmet from "react-helmet";
import('object-fit-polyfill');

// FavIcon
import FavIcon32 from './../resources/imgs/favicon/feirao-favicon-32.png';
import FavIcon76 from './../resources/imgs/favicon/feirao-favicon-76.png';

// Variaveis globais
const history = createBrowserHistory();

const preloadedState = window.__PRELOADED_STATE__;

delete window.__PRELOADED_STATE__;

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(reducers, preloadedState, composeEnhancers(applyMiddleware(thunk)));

const Main = () => {
    React.useEffect(() => {
        const jssStyles = document.querySelector('#jss-server-side-material');

        if(jssStyles){
            jssStyles.parentElement.removeChild(jssStyles);
        }
    }, []);

    return (
        <React.Fragment>
            <Helmet>
                <link rel="icon" type="image/png" href={FavIcon32} />
                <link rel="apple-touch-icon" sizes="76x76" href={FavIcon76} />
            </Helmet>
            <Provider store={store}>
                <Router history={history}>
                    <div>
                        {renderRoutes(Routes)}
                    </div>
                </Router>
            </Provider>
        </React.Fragment>
    )
}

ReactDOM.hydrate(<Main />, document.querySelector("#root"));