import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

export const InativoContainer = styled.div`
    width: 100%;
    background-color: #000;

    position: absolute;
    top: 114px;
    bottom: 0;
    left: 0;
    right: 0;

    h1{
        color: #FFF;
    }
`;

export const InativoImage = styled(props => (
    <a {...props}>
        <img src={props.src} />
    </a>
))`
    width: 100%;
    height: 100%;

    img{
        width: 100%;
    }
`;