import styled from 'styled-components';

export const PartnesContainer = styled.div`
    width: 100%;
    padding: 40px 10px 90px 10px;
    background-color: #000;
`;

export const PartnesTitle = styled.h4`
    width: 100%;
    color: #FFF;
    font-size: 30px;
    text-align: center;

    margin-top: 0px;
    margin-bottom: 20px;
`;

export const PartnesList = styled.div`
    width: 100%;
    max-width: 1220px;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    margin-left: auto;
    margin-right: auto;
`;

export const PartnesCard = styled.div`
    width: 107px;
    height: 107px;

    padding: 5px;
`;

export const PartnesImage = styled.img`
    width: 100%;
    height: 100%;

    background-color: #FFF;
    object-fit: contain;
`;