import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useLocation } from 'react-router-dom';
// Components
import { Formik, Field, useFormikContext } from 'formik';
import { FormContainer, FormSubmitting, FormContatado, FormTitle, FormSubtitle, FormSimpleText, FormBtn, FormContent, InputField, FormSubmittingTitle } from './formulario-styled';
import { useSelector, useDispatch } from 'react-redux';
import { sendForm } from './../../actions';
import { PhoneMask, CPFMask, CurrencyMask } from './formMaks';

// Actions
import { fetchLayout } from './../../actions';

const validationFieldType = {
    TEXT: Yup.string().required('O texto é obrigatório!'),
    NUMBER: Yup.string().required('Valor inválido!'),
    CURRENCY: Yup.string().required('Valor inválido!'),
    CPF: Yup.string().required('CPF inválido!'),
    PHONE: Yup.string().required('Telefone inválido'),
    EMAIL: Yup.string().email().required('E-mail inválido!')
}

const Formulario = () => {

    const location = useLocation();
    const dispatch = useDispatch();
    const formLayout = useSelector(state => state.formulario);

    const [validationSchema, setValidationSchema] = useState();
    const [contatado, setContatado] = useState();
    const [formText, setFormText] = useState();
    const [formColor, setFormColor] = useState();
    const [formFields, setFormFields] = useState();
    const [submitting, setSubmitting] = useState(false);
    const [isSubmitted, setSubmitted] = useState(false);

    useEffect(() => {
        if(!formLayout.formFields.length > 0) dispatch(fetchLayout())
    }, []);
    
    useEffect(() => {
        if(formLayout && formLayout.formFields.length > 0){
            setFormText(formLayout.formText);
            setFormColor(formLayout.formColor);
            setFormFields(formLayout.formFields);
            setContatado(formLayout.contatado);

            createValidationSchema(formLayout.formFields);
        } 
    }, [formLayout]);


    const createValidationSchema = (fields) => {
        let validationSchema = {};

        fields.map(item => {
            validationSchema[item.id] = validationFieldType[item.type.type];
        })

        setValidationSchema(Yup.object().shape(validationSchema));
    }

    const getInputMask = (value) => {
        const { type } = value;

        switch(type){
            case 'PHONE':
                return PhoneMask
            case 'CPF':
                return CPFMask;
            case 'CURRENCY':
                return CurrencyMask;
            default:
                return null;
        }
    }

    const customField = (props) => {

        const { item, onChange, error, errorText } = props;

        return (
            <InputField 
                onChange={onChange}
                label={item.descricao} 
                name={String(item.id)}
                variant="outlined" 
                bgcolor={formColor.bgColor}
                InputLabelProps={{
                    shrink: props.value
                }}
                InputProps={getInputMask(item.type) ?
                    { inputComponent: getInputMask(item.type) } 
                    : null
                }
                helperText={error ? errorText : ''}
                error={error? true : false} />
        )
    }

    const onSendClick = handleSubmit => () => {
        handleSubmit();
        setSubmitted(true);
    }

    return (
        <FormContainer bgcolor={formColor ? formColor.bgColor : null}>
            {formText && formColor && formFields && !contatado &&
                <>
                    <FormTitle textcolor={formColor.textColor}>
                        {formText.titulo}
                    </FormTitle>

                    <FormSubtitle textcolor={formColor.textColor}>
                        {formText.subtitulo}
                    </FormSubtitle>

                    {submitting && 
                        <FormSubmitting bgcolor={formColor ? formColor.bgColor : null}>
                            <FormSubmittingTitle textcolor={formColor.textColor}>Enviando...</FormSubmittingTitle>
                        </FormSubmitting>
                    }

                    <Formik
                        initialValues={{}}
                        onSubmit={(values) => {                            
                            setSubmitting(true);
                            values[0] = `${process.env.BASE_URL}${location.pathname}`;
                            dispatch(sendForm(values)).then(res => setSubmitting(false))
                        }}
                        validateOnChange={isSubmitted}
                        validateOnBlur={isSubmitted}
                        validationSchema={validationSchema}>

                        {({ handleSubmit, errors, values }) => (
                            <FormContent>
                                <div className="flex-display">

                                    {formFields.map((item, index) => {
                                        return (
                                            <Field key={`field-${index}`}>
                                                {({ form }) => {
                                                    const { setFieldValue } = useFormikContext();

                                                    const handleSearch = (e) => {
                                                        setFieldValue(e.target.name, e.target.value);
                                                    }

                                                    return (
                                                        <Field
                                                            onChange={handleSearch}
                                                            component={ customField }
                                                            item={item}                                                        
                                                            label={item.descricao}
                                                            errorText={errors[item.id]}
                                                            error={errors[item.id]} />
                
                                                    )
                                                }}
                                            </Field>
                                        )
                                    })}
                                </div>
                                
                                <br />
                               
                                <FormBtn btncolor={formColor.btnColor} btntextcolor={formColor.btnTextColor} onClick={onSendClick(handleSubmit)} disabled={submitting}>
                                    {submitting ? 'Enviando...' : formText.btnText}
                                </FormBtn>

                            </FormContent>
                        )}
                    </Formik>

                    <FormSimpleText color={formColor.textColor}>
                        <span>
                            {formText.juridico}
                        </span>
                    </FormSimpleText>

                </>
            }

            {contatado && formColor && formText &&
                <FormContatado color={formColor.textColor}>
                    <span>
                        {formText.agradecimento}
                    </span>
                </FormContatado>
            }

        </FormContainer>

    );
}

export default Formulario;