import React from 'react';
import styled from 'styled-components';

import { Paper, Divider } from '@material-ui/core';
import { NavLink } from 'react-router-dom';

export const CardContainer = styled(Paper)`
    &&{
        display: flex;

        ${({ orientation }) => orientation === 'vertical' && `
            width: 250px;
            height: 360px;

            flex-direction: column;
        `}

        ${({ orientation }) => orientation === 'horizontal' && `
            width: 100%;
            height: 200px;

            flex-direction: row;

            &:first-child{ margin-top: 0; }
        `}

        border-radius: 0;

        margin-top: 5px;
        margin-bottom: 10px;

        @media screen and (max-width: 1100px){
            ${({ orientation }) => orientation === 'vertical' && `
                width: 200px;
            `}
        }

        @media screen and (max-width: 768px){
            height: 120px;
        }

        .image-gallery{
            width: 250px;
            height: ${props => props.orientation === 'vertical' ? '180px' : '200px'};

            overflow: hidden;
            
            @media screen and (max-width: 1100px){
                ${({ orientation }) => orientation === 'vertical' && `
                    width: 200px;
                `}
            }

            @media screen and (max-width: 768px){
                width: 150px;
                height: 120px;
            }

            .image-gallery-image{
                height: ${props => props.orientation === 'vertical' ? '180px' : '200px'};
                object-fit: cover;

                @media screen and (max-width: 768px){
                    height: 120px;
                }
            }

            .image-gallery-svg{
                width: 30px;
            }

            .image-gallery-bullet{
                border: none;
                padding: 3px;
                margin-bottom: 5px;
                background-color: #FFF;
                
                &.active{
                    background-color: #f3123d;
                }

                @media screen and (max-width: 768px){
                    padding: 2px;
                }
            }

            .image-gallery-bullets{
                bottom: 0px;
            }

            .image-gallery-icon{
                opacity: 0;

                &.image-gallery-left-nav{
                    background: linear-gradient(-90deg,rgba(255,255,255, 0) 0%,rgba(0,0,0,0.5) 100%);
                }

                &.image-gallery-right-nav{
                    background: linear-gradient(90deg,rgba(255,255,255, 0) 0%,rgba(0,0,0,0.5) 100%);
                }
                

                &:focus{
                    outline: none;
                }

                transition: opacity .5s ease;

                // Oculta caso seja mobile
                @media screen and (max-width: 768px){
                    display: none;
                }
            }

            &:hover{
                .image-gallery-icon { opacity: 1; }
            }
        }
    }
`;

export const CardInfoContainer = styled(props => (
    <NavLink {...props}>
        <div>{props.children}</div>
    </NavLink>
))`
    width: ${props => props.orientation === 'horizontal' ? "calc(75% - 250px)" : '100%'};
    padding: 10px;

    @media screen and (max-width: 768px){
        width: calc(100% - 150px);

        display: flex;
        flex-direction: column;
    }
`;

export const CardTitulo = styled.p`
    font-size: ${props => props.orientation === 'vertical' ? '15px' : '21px'};
    font-weight: bold;

    margin-top: 0;
    margin-bottom: 0;

    
    
    @media screen and (max-width: 768px){
        font-size: 12px;
        
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }
`;

export const CardSubtitulo = styled.p`
    font-size: ${props => props.orientation === 'vertical' ? '12px' : '16px'};

    margin-top: 10px;
    margin-bottom: 0;

    @media screen and (max-width: 768px){
        font-size: 10px;
        margin-top: 5px;
    }
`;

export const CardCaracteristicas = styled.ul`

`;

export const CardCaracteristicaItem = styled.li`

`;

export const CardPriceContainer = styled(props => (
    <NavLink {...props}>
        <div>{props.children}</div>
    </NavLink>
))`
    width: ${props => props.orientation !== 'vertical' ? '25%' : '100%'};   
    height: ${props => props.orientation !== 'vertical' ? '100%' : null};

    div{
        width: 100%;
        height: ${props => props.orientation !== 'vertical' ? '100%' : null};
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items:  ${props => props.orientation !== 'vertical' ? 'center' : 'flex-start'};
    
        padding: 10px;
    
        margin-top: ${props => props.orientation === 'vertical' ? 'auto' : null};
    
        @media screen and (max-width: 768px){
            width: 100%;
            max-height: 60px;
            align-items: flex-start;
    
            padding: 10px 0;
            margin-top: auto;
        }
    }
`;

export const CardPriceOld = styled.span`
    font-size:  ${props => props.orientation !== 'vertical' ? '20px' : '13.5px'};
    font-weight: bold;
    position: relative;
    
    padding: 5px 10px;
    white-space: nowrap;

    margin-bottom: ${props => props.orientation !== 'vertical' ? '20px' : '10px'};

    &:after{
        content: "";
        border-top: ${props => props.orientation !== 'vertical' ? '2px' : '1px'} solid;
        border-color: #ff0000;

        position: absolute;
        top: 50%;
        left: 0;
        right: 0;

        -webkit-transform:rotate(5deg);
        -moz-transform:rotate(5deg);
        -ms-transform:rotate(5deg);
        -o-transform:rotate(5deg);
        transform:rotate(5deg);
    }

    @media screen and (max-width: 768px){
        font-size: 11px;
        margin-bottom: 0;
    }
`;

export const CardPriceNew = styled.span`
    font-size: ${props => props.orientation !== 'vertical' ? '2em' : '27px'};
    font-weight: bold;

    @media screen and (max-width: 768px) {
        font-size: 18px;
    }

`;

export const LigueConfira = styled.span`
    font-size: ${props => props.orientation !== 'vertical' ? '1.5em' : '27px'};
    font-weight: bold;
    text-align: center;

    @media screen and (max-width: 768px){
        font-size: 16px;
    }

`;

export const CardDivider = styled(Divider)`
    &&{
        height: 90%;
        margin-top: auto;
        margin-bottom: auto;
    }
`;