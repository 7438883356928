import styled, { keyframes } from 'styled-components';

import { IconButton, Collapse } from '@material-ui/core';

export const TopbarMain = styled.div`
    width: 100%;
    background-color: #FFF;
    padding: 20px 10px;

    display: flex;
    flex-direction: column;

    @media screen and (max-width: 768px){
        padding: 0 10px;
        position: fixed;
        top: 0;
        z-index: 999;
    }
}
`;

export const TopbarLogo = styled.img`
    height: 100px;

    @media screen and (max-width: 768px){
        height: 50px;
        margin-left: 12px;
    }
`;

export const TopbarContainer = styled.div`
    width: 100%;
    max-width: 1220px;
    margin-left: auto;
    margin-right: auto;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`;

export const BtnIcon = styled(IconButton)`
    &&{
        svg{
            width: 40px;
            height: 40px;

            color: #f96302;
        }
    }
`;

export const TopbarCollapse = styled(Collapse)`
    &&{
        width: 100%;
        height: calc(100% - 64px) !important;
        overflow: auto;
        background-color: #FFF;

        position: fixed;
        top: 64px;
        left: 0;
        z-index: 99;

        .topbar-collapse-container{
            width: 100%;
    
            padding: 10px;
    
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }
`;