import styled from 'styled-components';
import { Select } from '@material-ui/core';

export const BuscaContainer = styled.div`
    background-color: #000;

    padding: 20px 0;

    display: flex;
    flex-direction: row;

    h1{ color: #FFF; }
`;

export const BuscaResults = styled.div`
    width: calc(100% - 300px);

    display: flex;
    flex-direction: column;

    @media screen and (max-width: 1220px){
        width: 100%;
        padding: 10px;
    }
`;

export const BuscaResumo = styled.div`
    width: 100%;
    padding: 15px;
    background-color: #f96302;

    margin-bottom: 20px;

    p{
        font-size: 21px;
        color: #FFF;

        margin-top: 0;
        margin-bottom: 0;
    }

    strong{
        font-size: 28px;
        font-weight: bold;

        text-transform: uppercase;
    }

    @media screen and (max-width: 1220px){
        p{ font-size: 16px; }

        strong{ font-size: 21px; }
    }
`;

export const Content = styled.div`
    width: 100%;
    max-width: 1220px;

    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;

    margin-left: auto;
    margin-right: auto;
`;

export const BuscaCards = styled.div`
    width: 100%;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
`;

export const BuscaTools = styled.div`
    width: 100%;

    margin-top: 5px;
    margin-bottom: 10px;

    display: flex;
    flex-direction: row;
    justify-content: flex-end;
`;

export const BuscaToolBtn = styled(Select)`
    &&{
        border-radius: 2px;
        background-color: #FFF;

        span{
            font-size: 16px;
            color: #666666;
        }
        
        .MuiSelect-outlined.MuiSelect-outlined{
            padding: 10px 35px 10px 20px;
        }

        @media screen and (max-width: 768px){
            width: 100%;
        }
    }
`;

export const BuscaNotFound = styled.p`
    font-size: 26px;
    color: #FFF;

    width: 600px;

    margin-left: auto;
    margin-right: auto;

    @media screen and (max-width: 768px){
        width: 100%;
        font-size: 24px;

        text-align: center;
    }
`;