import styled from 'styled-components';

export const GalleryContainer = styled.div`
    width: 60%;

    @media screen and (max-width: 768px){
        width: 100%;
    }
`;

export const GalleryGrid = styled.div`
    width: 100%;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;

    .thumbnail{ object-fit: cover; }
`;

export const MainImage = styled.img`
    width: 100%;
    margin-bottom: 10px;

    object-fit: cover;

    cursor: pointer;
`;

export const Thumbnails = styled.img`
    width: calc(25% - 5px);
    height: 90px;

    cursor: pointer;

    object-fit: cover;
    margin-right: 5px;

    &:last-child{ margin-right: 0px; }
`;

export const MobileGallery = styled.div`
    width: 100%;

    .image-gallery{
        width: 100%;
        height: 350px;

        overflow: hidden;

        .image-gallery-image{
            height: 350px;
            object-fit: cover;
        }

        .image-gallery-svg{
            width: 30px;
        }

        .image-gallery-bullet{
            border: none;
            padding: 3px;
            margin-bottom: 5px;
            background-color: #FFF;
            
            &.active{
                background-color: #f3123d;
            }

            @media screen and (max-width: 768px){
                padding: 2px;
            }
        }

        .image-gallery-bullets{
            bottom: 0px;
        }

        .image-gallery-icon{
            opacity: 0;

            &.image-gallery-left-nav{
                background: linear-gradient(-90deg,rgba(255,255,255, 0) 0%,rgba(0,0,0,0.5) 100%);
            }

            &.image-gallery-right-nav{
                background: linear-gradient(90deg,rgba(255,255,255, 0) 0%,rgba(0,0,0,0.5) 100%);
            }
            

            &:focus{
                outline: none;
            }

            transition: opacity .5s ease;

            // Oculta caso seja mobile
            @media screen and (max-width: 768px){
                display: none;
            }
        }

        &:hover{
            .image-gallery-icon { opacity: 1; }
        }
    }
`;