import React, { useState, useEffect } from 'react';
import { PartnesContainer, PartnesTitle, PartnesList, PartnesCard, PartnesImage } from './partnes-styled';
import { NavLink } from 'react-router-dom';

const Partnes = ({ partnesList }) => {
    return (
        <PartnesContainer>
            <PartnesTitle>Parceiros:</PartnesTitle>

            <PartnesList>
                {partnesList && partnesList.map((item, index) => {
                    return (
                        <NavLink key={`anunciante-${index}`} to={`/anunciante/${item.id}`}>
                            <PartnesCard>
                                <PartnesImage src={item.url_logo} data-object-fit="contain"/>
                            </PartnesCard>
                        </NavLink>
                    )
                })}
            </PartnesList>
        </PartnesContainer>
    )
}

export default Partnes;