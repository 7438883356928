import React, { useState, useEffect } from 'react';
import { InputLabel, Select, MenuItem, Hidden } from '@material-ui/core';
import { FilterSelect, FilterBtn, FilterForm, FilterCleanBtn } from './filter-styled';
import MinMax from '../minmax/min-max';
import TagSelect from '../tag-select/TagSelect';
import { Field, useFormikContext } from 'formik';
import { useSelector } from 'react-redux';

export const veiculoSearch = {
    anoFabricacaoMaximo: null,
    anoFabricacaoMinimo: null,
    caracteristicas: null,
    corVeiculo: null,
    documentacao: null,
    feirao: null,
    marca: null,
    modelo: null,
    ordenacao: null,
    pagina: 0,
    paginado: true,
    parceiroFeirao: null,
    qtdRegistros: 12,
    quilometragemMaxima: null,
    quilometragemMinima: null,
    secao: 'VEICULOS',
    tipoCombustivel: null,
    vlrMaximo: null,
    vlrMinimo: null
}

const FilterVeiculos = ({ closeTopBar, handleSubmit, hidePartners, cleanFilter }) => {
    const {setValues, setFieldValue, values} = useFormikContext()
    const [oldValues, setOldValues] = useState();
    const filtro = useSelector(state => state.busca.filtros);
    const vendedores = useSelector(state => state.busca.filtroVendedor);

    useEffect(() => {
        if(values && values !== oldValues){
            setOldValues(values);
            handleSubmit();
        }
    }, [values]);

    const selectMarca = (e) => {
        setValues({
            [e.target.name]: e.target.value,
            modelo: null,
            versao: null
        });
    }

    const selectModelo = (e) => {
        setValues({
            [e.target.name]: e.target.value,
            versao: null
        })
    }

    return (
        <FilterForm>
            
            {/* Marca */}
            <FilterSelect variant="outlined">
                <InputLabel>Marca</InputLabel>
                <Select className="select" name="marca" value={values.marca || ''} onChange={selectMarca}>
                    <MenuItem value={null}>Todos</MenuItem>
                    {filtro.marcas &&
                        filtro.marcas.map((item, index) => 
                            <MenuItem key={'marca-' + index} value={item.valor}>{item.descricao}</MenuItem>
                        )
                    }
                </Select>
            </FilterSelect>

            {/* Modelo */}
            <FilterSelect variant="outlined" disabled={!Boolean(filtro.modelos)}>
                <InputLabel>Modelo</InputLabel>
                <Select className="select" name="modelo" value={values.modelo || ''} onChange={selectModelo}>
                    <MenuItem value={null}>Todos</MenuItem>
                    {filtro.modelos &&
                        filtro.modelos.map((item, index) => 
                            <MenuItem key={'modelo-' + index} value={item.valor}>{item.descricao}</MenuItem>
                        )
                    }
                </Select>
            </FilterSelect>

            {/* Versão */}
            <FilterSelect variant="outlined" disabled={!Boolean(filtro.versoes)}>
                <InputLabel>Versão</InputLabel>
                <Select className="select" name="versao" value={values.versao || ''} onChange={(e) => setFieldValue(e.target.name, e.target.value)}>
                    <MenuItem value={null}>Todos</MenuItem>
                    {filtro.versoes &&
                        filtro.versoes.map((item, index) =>
                            <MenuItem key={'versao-' + index} value={item.valor}>{item.descricao}</MenuItem>
                        )
                    }
                </Select>
            </FilterSelect>

            {/* Ano */}
            <Field>
                {({ form }) => {
                    const { anoFabricacaoMinimo, anoFabricacaoMaximo} = form.values;

                    const handleSearch = ({min, max}) => {
                        setFieldValue('anoFabricacaoMinimo', min);
                        setFieldValue('anoFabricacaoMaximo', max);
                    }

                    return <MinMax titulo="Ano" maxLength="4" search={handleSearch} min={anoFabricacaoMinimo} max={anoFabricacaoMaximo}/>
                }}
            </Field>

            {/* Preço */}
            <Field>
                {({ form }) => {
                    const { vlrMinimo, vlrMaximo} = form.values;

                    const handleSearch = ({min, max}) => {
                        setFieldValue('vlrMinimo', min);
                        setFieldValue('vlrMaximo', max);
                    }

                    return <MinMax titulo="Preço" search={handleSearch} min={vlrMinimo} max={vlrMaximo}/>
                }}
            </Field>

            {/* Quilometragem */}
            <Field>
                {({ form }) => {
                    const { quilometragemMinima, quilometragemMaxima} = form.values;

                    const handleSearch = ({min, max}) => {
                        setFieldValue('quilometragemMinimo', min);
                        setFieldValue('quilometragemMaxima', max);
                    }

                    return <MinMax titulo="Quilometragem" search={handleSearch} min={quilometragemMinima} max={quilometragemMaxima}/>
                }}
            </Field>

            {/* Vendedor */}
            {!hidePartners &&
                <FilterSelect variant="outlined" disabled={!Boolean(vendedores)}>
                    <InputLabel>Vendedor</InputLabel>
                    <Select className="select" name="parceiroFeirao" value={values.parceiroFeirao || ''} onChange={(e) => setFieldValue(e.target.name, e.target.value)}>                
                        {vendedores &&
                            vendedores.map((item, index) =>
                                <MenuItem key={'parceiro-' + index} value={item.id}>{item.nome_fantasia}</MenuItem>
                            )
                        }                    
                    </Select>
                </FilterSelect>
            }

            {/* Documentação */}
            <FilterSelect variant="outlined">
                <InputLabel>Documentação</InputLabel>
                <Select className="select" name="documentacao" value={values.documentacao || ''} onChange={(e) => setFieldValue(e.target.name, e.target.value)}>
                    {filtro.documentacoes &&
                        filtro.documentacoes.map((item, index) =>
                            <MenuItem key={'documentacao-' + index} value={item.valor}>{item.descricao}</MenuItem>
                        )
                    }
                </Select>
            </FilterSelect>

            {/* Combustivel */}
            <FilterSelect variant="outlined">
                <InputLabel>Combustível</InputLabel>
                <Select className="select" name="tipoCombustivel" value={values.combustivel || ''} onChange={(e) => setFieldValue(e.target.name, e.target.value)}>
                    {filtro.tiposCombustivel &&
                        filtro.tiposCombustivel.map((item, index) =>                     
                            <MenuItem key={'combustivel-' + index} value={item.valor}>{item.descricao}</MenuItem>
                        )
                    }
                </Select>
            </FilterSelect>

            {/* Cor */}
            <FilterSelect variant="outlined">
                <InputLabel>Cores</InputLabel>
                <Select className="select" name="corVeiculo" value={values.corVeiculo || ''} onChange={(e) => setFieldValue(e.target.name, e.target.value)}>
                    {filtro.cores &&
                        filtro.cores.map((item, index) =>                     
                            <MenuItem key={'cor-' + index} value={item.valor}>{item.descricao}</MenuItem>
                        )
                    }
                </Select>
            </FilterSelect>

            {/* Opcionais */}
            <Field>
                {({ form }) => {
                    const { caracteristicas } = form.values;

                    const handleSearch = (selectedTags) => {

                        if(selectedTags && selectedTags.length > 0){
                            setFieldValue('caracteristicas', selectedTags);
                        } else {
                            setFieldValue('caracteristicas', null)
                        }
                        
                    }

                    return <TagSelect options={filtro.caracteristicas} onChange={handleSearch}/>
                }}
            </Field>

            <Hidden smUp>
                <FilterBtn variant="outlined" onClick={closeTopBar || null}>
                    Filtrar
                </FilterBtn>
            </Hidden>

            <FilterCleanBtn type="submit" onClick={cleanFilter}>
                Limpar Filtro
            </FilterCleanBtn>
            
        </FilterForm>
    )
};

export default FilterVeiculos;