import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { TopbarMain, TopbarContainer, TopbarLogo, BtnIcon, TopbarCollapse } from './topbar-styled';
import { goToAnchor, configureAnchors, removeHash } from 'react-scrollable-anchor';

// Icons
import MenuIcon from '@material-ui/icons/Menu';

// Images
import LogoClassi from './../../../resources/imgs/classi_feirao.png';
import SearchAutocomplete from '../search-autocomplete/searchAutocomplete';
import { Hidden, Divider } from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import Filter from '../filter/filter';

const Topbar = ({ hideSearch, redirectOnSearch }) => {
    const [open, setOpen] = useState(false);
    configureAnchors({ offset: -60, scrollDuration: 200 });

    const scroll = () => {
        goToAnchor('busca-resumo');
        removeHash();
    };

    return (
        <TopbarMain>
            <TopbarContainer>
                <NavLink to="/">
                    <TopbarLogo src={LogoClassi} />
                </NavLink>

                {/* {!hideSearch &&
                    <Hidden smDown>
                        <SearchAutocomplete callback={() => scroll()} redirectOnSearch={redirectOnSearch}/>
                    </Hidden>
                } */}

                <Hidden smUp>
                    <BtnIcon aria-label='search' onClick={() => setOpen(!open)}>
                        <MenuIcon />
                    </BtnIcon>
                </Hidden>
            </TopbarContainer>

            {!hideSearch &&
                <Hidden smUp>
                    <TopbarCollapse in={open}>
                        {/* <div className="topbar-collapse-container">
                            <SearchAutocomplete callback={() => scroll()} redirectOnSearch={redirectOnSearch}/>
                        </div> */}

                        <Divider />

                        <Filter 
                            callback={() => {setOpen(false); scroll()}}
                        />
                    </TopbarCollapse>
                </Hidden>
            }
        </TopbarMain>
    )
}

export default withRouter(Topbar);