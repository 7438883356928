import { combineReducers } from 'redux';
import layoutReducer from './layoutReducer';
import anuncianteReducer from './anuncianteReducer';
import buscaReducer from './buscaReducer';
import anuncioReducer from './anuncioReducer';
import formReducer from './formularioReducer';

export default combineReducers({
    layout: layoutReducer,
    anuncio: anuncioReducer,
    anunciante: anuncianteReducer,
    busca: buscaReducer,
    formulario: formReducer
})