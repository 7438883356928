import styled from 'styled-components';
import { Paper, Select, FormControl, TextField, Button, darken } from '@material-ui/core';
import { Form } from 'formik';

export const FilterContainer = styled.div`
    width: 280px;

    display: flex;
    flex-direction: column;

    @media screen and (max-width: 768px){
        width: 100%;
    }
`;

export const FilterPaper = styled(Paper)`
    width: 280px;

    padding: 25px 10px;
    background-color: #000;

    display: flex;
    flex-direction: column;

    h3{
        color: #666666;
        font-weight: normal;

        margin-top: 0;
        margin-bottom: 0;
    }

    p{
        color: #FFF;
    }

    @media screen and (max-width: 768px){
        width: 100%;
    }
`;

export const FilterForm = styled(Form)`
    &&{
        display: flex;
        flex-direction: column;
    }
`

export const FilterSelect = styled(FormControl)`
    .MuiFormLabel-root{
        transform: translate(14px, 14px) scale(1);
    }

    &&{
        margin-top: 5px;
        margin-bottom: 10px;
        
        fieldset{
            border: none;
        }

        .select{
            background-color: #eeeeee;
            border: none;
        }

        &.noMarginTop{
            margin-top: 0;
        }

        .MuiFormLabel-root{
            font-size: 14px;
        }

        .MuiSelect-root{
            padding: 10px;
        }
    }
`;

export const FilterText = styled(TextField)`
    .MuiFormLabel-root{
        transform: translate(14px, 14px) scale(1);
    }

    &&{
        .MuiFormLabel-root{
            font-size: 14px;
        }

        .MuiInputBase-input{
            padding: 10px;
        }

        fieldset{
            border: none;
        }

        .MuiInputBase-root{
            background-color: #eeeeee;
        }
    }
`;

export const FilterBtn = styled(Button)`
    &&{
        height: 40px;

        color: #FFF;
        border: 0;
        text-transform: none;
        background-color: #f96302;

        border-radius: 0;

        margin-top: 10px;

        &:hover{
            background-color: ${darken('#f96302', 0.2)};
        }
    }
`;

export const FilterCleanBtn = styled(Button)`
    && {
        width: 100%;
        font-size: 14px;
        color: #f96302;
        text-decoration: underline;
        text-transform: none;

        margin-top: 10px;
    }
`;