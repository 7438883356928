import styled from 'styled-components';
import { TextField, Button } from '@material-ui/core';
import { FilterText } from '../filter/filter-styled';

export const MinMaxContainer = styled.div`
    width: 100%;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;

    margin-top: 5px;
    margin-bottom: 10px;
`;

export const MinMaxTitle = styled.p`
    &&{
        width: 100%;

        font-size: 14px;
        color: #666666;

        margin-top: 5px;
        margin-bottom: 7px;
        margin-left: 10px;
    }
`;

export const MinMaxTextField = styled(FilterText)`
    &&{
        width: calc(50% - 5px);
    }
`;

export const MinMaxBtn = styled(Button)`
    &&{
        width: 100%;
        border: 2px solid #f96302;

        padding: 0;
        margin-top: 5px;
    }
`;