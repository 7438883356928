import React, { useState, useEffect } from 'react';
import { MinMaxContainer, MinMaxTitle, MinMaxTextField } from './min-max-styled';

const MinMax = ({ titulo, min, max, search, maxLength }) => {
    const [minValue, setMinValue] = useState(min || null);
    const [maxValue, setMaxValue] = useState(max || null);
    const [loading, setLoading] = useState();

    useEffect(() => {
        if(loading){
            clearTimeout(loading);
        }

        setLoading(setTimeout(() => search({min: parseInt(minValue) || null, max: parseInt(maxValue) || null}), 1000));
    }, [minValue, maxValue]);

    useEffect(() => {
        setMinValue(min);
        setMaxValue(max);
    }, [min, max]);

    return (
        <MinMaxContainer>
            <MinMaxTitle>{titulo}</MinMaxTitle>

            <MinMaxTextField label="de" variant="outlined" maxLength={maxLength || null} name="min" value={minValue || ''} onChange={e => setMinValue(e.target.value)}/>
            <MinMaxTextField label="até" variant="outlined" maxLength={maxLength || null} name="max" value={maxValue || ''} onChange={e => setMaxValue(e.target.value)}/>
        </MinMaxContainer>
    );
}

export default MinMax;