import React from 'react';
import { ResumoContainer, ResumoItem, ItemTitle, ItemDescription } from './resumo-styled';

const Resumo = ({info}) => {

    return(
        <ResumoContainer>
            {info.anoFabricacao &&
                <ResumoItem>
                    <ItemTitle>Ano</ItemTitle>
                    <ItemDescription>{`${info.anoFabricacao}/${info.anoVersao}`}</ItemDescription>
                </ResumoItem>
            }

            {info.quilometragem &&
                <ResumoItem>
                    <ItemTitle>Km</ItemTitle>
                    <ItemDescription>{`${info.quilometragem}`}</ItemDescription>
                </ResumoItem>
            }

            {info.tipoCambio &&
                <ResumoItem>
                    <ItemTitle>Câmbio</ItemTitle>
                    <ItemDescription>{info.tipoCambio.descricao}</ItemDescription>
                </ResumoItem>
            }

            {info.tipoCombustivel &&
                <ResumoItem>
                    <ItemTitle>Combustível</ItemTitle>
                    <ItemDescription>{info.tipoCombustivel.descricao}</ItemDescription>
                </ResumoItem>
            }

            {info.corVeiculo &&
                <ResumoItem>
                    <ItemTitle>Cor</ItemTitle>
                    <ItemDescription>{info.corVeiculo.descricao}</ItemDescription>
                </ResumoItem>
            }
        </ResumoContainer>
    )

}

export default Resumo;