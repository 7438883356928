import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useSelector, useDispatch } from 'react-redux';
import { AnuncianteContainer, FabBtn, AnuncianteSearch, AnuncianteResults, Content, AnuncioInfiniteScroll } from './Anunciante-styled';
import Topbar from '../../components/topbar/topbar';
import Banner from '../../components/banner/banner';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Formulario from '../../components/formulario/formulario';
import ReactGA from 'react-ga';

// Actions
import { fetchAnunciante, fetchLayout } from './../../actions';
import AnuncianteInfo from '../../components/anunciante-info/anunciante-info';
import { Hidden, MenuItem } from '@material-ui/core';
import Filter from '../../components/filter/filter';
import Card from '../../components/card/card';
import Loading from '../../components/loading/loading';
import { BuscaTools, BuscaToolBtn, BuscaResumo } from '../Busca/Busca-styled';
import { FETCH_SEARCH_OBJECT } from '../../actions/types';
import {veiculoResultsText, getIdFromURL} from '../../../helpers/stringHelper';
import { initAnalytics, enviarPageView } from '../../components/analytics-gjc/analytics-gjc';

const vitrineType = process.env.VITRINE_TYPE.toLowerCase();

const Anuciante = () => {
    const params = useParams();
    const dispatch = useDispatch();
    
    // Carrega state do servidor
    const idFeirao = useSelector(state => state.layout.idFeirao);
    const anunciante = useSelector(state => state.anunciante.anuncianteLayout);
    const layout = useSelector(state => state.layout.layout);
    const anuncios = useSelector(state => state.busca.buscaResults);
    const filtros = useSelector(state => state.busca.filtros);
    const searchObj = useSelector(state => state.busca.searchObj);

    // Variaveis da página
    const isMobile = useMediaQuery('(min-width:768px)');
    const [loading, setLoading] = useState(true);
    const [firstLoad, setFirstLoad] = useState(true);
    const [hasMorePage, setHasMorePage] = useState(false);
    const [oldAnuncios, setOldAnuncios] = useState(anuncios);

    // Caso o state esteja vazio ele faz a consulta
    useEffect(() => {

        if(Object.keys(layout).length === 0){
            dispatch(fetchLayout());
        }

        if (Object.keys(anunciante).length === 0 || anunciante.id !== params.id) {
            dispatch(fetchAnunciante({ anuncianteId: parseInt(params.id)}));
        }

        if(searchObj.pagina > 0){

            dispatch({ 
                type: FETCH_SEARCH_OBJECT, 
                payload: Object.assign(searchObj, { parceiroFeirao: getIdFromURL(params.id), pagina: 0 }) 
            });

        } else {

            dispatch({ 
                type: FETCH_SEARCH_OBJECT, 
                payload: Object.assign(searchObj, { parceiroFeirao: getIdFromURL(params.id) }) 
            });

        }

    }, []);

    // Exibe o loading se carregar os anuncio do zero
    useEffect(() => {
        if(searchObj.pagina === 0){
            setLoading(true);
        }
    }, [searchObj]);

    // Remove a tela de loading
    // e arquiva um historico de anuncio para futuras validações
    useEffect(() => {        
        
        if(anuncios && anuncios !== oldAnuncios){

            setOldAnuncios(anuncios);
            setLoading(false);
            setFirstLoad(false);

            //  Analytics
            initAnalytics('vitrine_feirao_anunciante', process.env.SITEID_ANALYTICS);
            enviarPageView({ idFeirao: idFeirao, idContrato:  anunciante.id});

            ReactGA.initialize(`${process.env.CODIGO_ANALYTICS}`);
            ReactGA.pageview(`${vitrineType + window.location.pathname}`);

        }

    }, [anuncios]);

    // Caso tenha mais páginas ele habilita a rolagem infinita
    useEffect(() => {

        if(filtros && filtros.qtdPaginas > searchObj.pagina + 1){
            setHasMorePage(true);

            setLoading(false);
            setFirstLoad(false);
        } else {
            setHasMorePage(false);

            setLoading(false);
            setFirstLoad(false);
        }

    }, [filtros]);

    // Evento de ordenação
    const handleSort = (e) => {
        const filter = Object.assign(searchObj, { ordenacao: e.target.value, pagina: 0 });
        dispatch({type: FETCH_SEARCH_OBJECT, payload: filter});
    }

    // Paginação
    // Rolagem infinita
    const nextPage = () => {
        const pagina = searchObj.pagina || 0;
        const newFilter = Object.assign(searchObj, { pagina: pagina + 1 });
        
        dispatch({ type: FETCH_SEARCH_OBJECT, payload: newFilter });
    }

    return (
        <React.Fragment>
            <Helmet>
                <title>{layout.nome_feirao}</title>
            </Helmet>

            <Topbar />

            {/* Só exibe o conteudo da página caso tenha os dados */}
            {Object.keys(anunciante).length > 0 &&
                <AnuncianteContainer>
                    <Banner desktopUrl={anunciante.url_capa_desktop} mobileUrl={anunciante.url_capa_mobile} />

                    {/* Formulário - Leads  */}
                    <Formulario />

                    <AnuncianteInfo
                        id={anunciante.id}
                        idFeirao={idFeirao}
                        name={anunciante.nome_fantasia}
                        logo={anunciante.url_logo} 
                        whatsapp={anunciante.whatsapp} 
                        phoneList={anunciante.telefones} 
                        localizacao={anunciante.localizacao}
                    />

                    <AnuncianteSearch>
                        <Content>
                            <Hidden smDown>
                                <Filter 
                                    defaultFilter={{parceiroFeirao: parseInt(params.id)}}
                                    hidePartners
                                />
                            </Hidden>

                            <AnuncianteResults>
                                {/* Component de loading */}
                                {`${loading}`}
                                {loading && <Loading />}

                                {!loading && anuncios && anuncios.length > 0 &&
                                    <BuscaResumo id="busca-resumo">
                                        {veiculoResultsText(searchObj, filtros)}
                                    </BuscaResumo>
                                }

                                {!loading && filtros && filtros.ordenacao && anuncios && anuncios.length > 0 &&
                                    <Hidden smDown>
                                        <BuscaTools>
                                            <BuscaToolBtn
                                                variant="outlined"
                                                onChange={handleSort}
                                                value={searchObj.ordenacao || filtros.ordenacao[0].valor}
                                            >
                                                {filtros.ordenacao.map(item => 
                                                    <MenuItem
                                                        key={item.valor}
                                                        value={item.valor}
                                                    >{item.descricao}</MenuItem>    
                                                )}
                                            </BuscaToolBtn>
                                        </BuscaTools>
                                    </Hidden>

                                }

                                {anuncios &&
                                    <AnuncioInfiniteScroll
                                        dataLength={anuncios.length}
                                        next={() => nextPage()}
                                        hasMore={hasMorePage}
                                        loader={<Loading />}>

                                        {anuncios.map((card, index) => {
                                            return (
                                                <Card 
                                                    key={`card-${card.id}`} 
                                                    cardType={card.secao.value} 
                                                    orientation={isMobile ? "vertical" : "horizontal"} 
                                                    cardInfo={card} />
                                            )
                                        })}

                                    </AnuncioInfiniteScroll>
                                }
                            </AnuncianteResults>

                        </Content>
                    </AnuncianteSearch>
                </AnuncianteContainer>
            }
        </React.Fragment>
    )
}

export const loadData = ({store, match}) => {
    return Promise.all([
        store.dispatch(fetchLayout()),
        store.dispatch(fetchAnunciante({ anuncianteId: match.params.id }))
    ])
}
export default Anuciante;