import React, { useState, useEffect } from 'react';
import { BuscaContainer, BuscaResults, Content, BuscaResumo, BuscaCards } from './Busca-styled';
import Topbar from '../../components/topbar/topbar';
import Filter from '../../components/filter/filter';
import Card from '../../components/card/card';

// Mockups
import { searchMockup } from './../../../mockups/busca';
import { Hidden } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { fetchLayout } from '../../actions';

const Busca = () => {
    // Carrega os dados do store
    const layout = useSelector(state => state.layout.layout);
    const anuncios = useSelector(state => state.busca.buscaResults);

    const constructTitle = () => {
        let text = '';
        text = (<p><strong>{busca.qtdTotalRegistros || 0}</strong> resultados</p>);
        return text;
    }

    return (
        <React.Fragment>
            <Topbar />

            <BuscaContainer>
                <Content>
                    <Hidden smDown>
                        <Filter />
                    </Hidden>

                    <BuscaResults>
                        <BuscaResumo>
                            <p>12* resultados para</p>
                        </BuscaResumo>

                        <BuscaCards>
                            {anuncios &&
                                anuncios.map((card, index) => {
                                    return (
                                    <Card 
                                        key={`card-${index}`} 
                                        cardType={card.secao.value} 
                                        orientation="horizontal" 
                                        cardInfo={card} 
                                    />)
                                })
                            }
                        </BuscaCards>
                    </BuscaResults>
                </Content>
            </BuscaContainer>
        </React.Fragment>
    )

}

export const loadData = ({ store }) => {
    return store.dispatch(fetchLayout());
}

export default Busca;