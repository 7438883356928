import { FETCH_ANUNCIO, FETCH_ANUNCIO_RELACIONADOS } from '../actions/types';

const INITIAL_STATE = {
    anuncio: {},
    relacionados: [],
};

export default (state = INITIAL_STATE, action) => {
    switch(action.type){
        case FETCH_ANUNCIO:
            return { ...state, anuncio: action.payload }
        case FETCH_ANUNCIO_RELACIONADOS:
            return { ...state, relacionados: action.payload}
        default:
            return state;
    }
}