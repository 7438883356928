import styled from 'styled-components';

export const ErrorContainer = styled.div`
    width: 100%;
    background-color: #000;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    position: absolute;
    top: 114px;
    bottom: 0;
    left: 0;
    right: 0;

    @media screen and (max-width: 768px){
        top: 64px;
    }
`;

export const ErrorTitle = styled.h1`
    font-size: 120px;    
    color: #FFF;
    margin-top: 0;
    margin-bottom: 0;
`;

export const ClassiLogo = styled.img`
    width: 270px;
`;

export const ErrorText = styled.h2`
    color: #FFF;
    margin-top: 0;
    margin-bottom: 0;
`;