import { 
    FETCH_LAYOUT, 
    FETCH_ANUNCIANTE_LAYOUT, 
    FETCH_ANUNCIANTE_INFO, 
    FETCH_BUSCA,
    FETCH_BUSCA_AUTOCOMPLETE,
    FETCH_ANUNCIO, 
    FETCH_FORMULARIO,
    SET_FORM_CONTATADO,
    FETCH_FILTRO_VENDEDOR,
    FETCH_LAYOUT_INATIVO,
    FETCH_ANUNCIO_RELACIONADOS,
    FETCH_FEIRAO_ID
} from './types';

import axios from 'axios';

// Consulta layout do feirão
export const fetchLayout = () => async (dispatch, getState, api) => {
    let requestContext = undefined;

    await axios.get(`${process.env.FEIRAO_API}/feirao`)
        .then(res => {
            dispatch({
                type: FETCH_FEIRAO_ID,
                payload: res.data.id
            });
            dispatch({
                type: FETCH_LAYOUT,
                payload: res.data
            });
            dispatch({
                type: FETCH_FORMULARIO,
                payload: res.data
            }),
            dispatch({
                type: FETCH_FILTRO_VENDEDOR,
                payload: res.data.parceiros
            })
        })
        .catch(error => {
            if(error.response.data){
                dispatch({
                    type: FETCH_LAYOUT_INATIVO,
                    payload: error.response.data
                });
                requestContext = Object.assign({}, {error: {status: 'INATIVO'}})
            } else {
                requestContext = Object.assign({}, {error: error.response.data});
            }
        })
    
    return requestContext;
};

// Consulta layout da pagina do anunciante
export const fetchAnunciante = ({ anuncianteId }) => async (dispatch, getState, api) => {
    let requestContext = undefined;

    await axios.get(`${process.env.FEIRAO_API}/feirao/parceiro/${anuncianteId}`)
        .then(res => {
            dispatch({
                type: FETCH_ANUNCIANTE_LAYOUT,
                payload: res.data
            })
        })
        .catch(error => {
            requestContext = Object.assign(
                {},
                {
                    error: error.response.data
                }                
            )
        });
    
    return requestContext;
}

// Faz pesquisa de anuncios
export const fetchBusca = (filter) => (dispatch, getState, api) => {
    axios.post(`${process.env.FEIRAO_API}/feirao/anuncios-veiculos`, filter)
        .then(res => {
            dispatch({
                type: FETCH_BUSCA,
                payload: res.data
            });
        })
}

// Faz pesquisa de um anuncio a partir de um ID
export const fetchAnuncioById = ({ anuncioId }) => async (dispatch, getState, api) => {
    let requestContext = undefined;

    await axios.get(`${process.env.FEIRAO_API}/feirao/anuncio/${anuncioId}`)
        .then(res => {
            dispatch({
                type: FETCH_ANUNCIO,
                payload: res.data
            })
        })
        .catch(error => {
            requestContext = Object.assign(
                {},
                {
                    error: error.response.data
                }                
            )  
        });
    
    return requestContext;
};

// Consulta informações do anunciante
export const fetchAnuncianteInfo = (id) => (dispatch, getState, api) => {
    axios.get(`${process.env.FEIRAO_API}/vitrine-veiculos/anunciantes/{id}?id=${id}`)
        .then(res => {
            dispatch({
                type: FETCH_ANUNCIANTE_INFO,
                payload: res.data
            })
        })
        .catch(error => {
            dispatch({
                type: FETCH_ANUNCIANTE_INFO,
                payload: null
            });            
        })
}

// Autocomplete veiculos
export const fetchAutocomplete = (query) => (dispatch, getState, api) => {
    axios.get(`${process.env.FEIRAO_API}/feirao/auto-complete-veiculos/${query}`)
        .then(res => {
            dispatch({
                type: FETCH_BUSCA_AUTOCOMPLETE,
                payload: res.data
            })
        })
}

export const fetchAnunciosSimilares = (anuncioId) => (dispatch, getState, api) => {
    axios.get(`${process.env.FEIRAO_API}/feirao/consultar-similares?idAnuncio=${anuncioId}&qtdAnuncios=8`)
        .then(res => {
            dispatch({
                type: FETCH_ANUNCIO_RELACIONADOS,
                payload: res.data
            })
        })
}

// Send form
export const sendForm = (form) => (dispatch) => new Promise((resolve, reject) => {
    axios.post(`${process.env.FEIRAO_API}/feirao/enviar-lead`, form)
        .then(res => {
            dispatch({
                type: SET_FORM_CONTATADO,
                payload: true
            })

            resolve();
        }).
        catch(err => reject(err));
});