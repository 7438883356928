export const sendAnalytics = (event, data) => {
    const userEmail = '';
    let copyObj = {};

    Object.assign(copyObj, data);

    // Caso seja o filtro de uma busca, ele deve remover alguns dados
    if(event == 'filtrar'){
        cleanPaginacao(copyObj);
        cleanData(copyObj);
    }

    let jsonAnalytics = {
        categoria: 'acao', 
        tipo: event,
        dados: copyObj,
        email: userEmail ?  userEmail : ''
    }

    if(window.AnalyticsGJC){
        window.AnalyticsGJC.filaAnalyticsGJC.push(['enviarEvento', jsonAnalytics]);
    } 
}

export const enviarPageView = (params) => {
    
    let jsonPageView = {};

    if(params) {
        jsonPageView.dados = params;
    }

    window.AnalyticsGJC.filaAnalyticsGJC.push(['enviarPageView', jsonPageView]);

}

function cleanPaginacao(obj){
    delete obj.pagina;
    delete obj.paginado;
    delete obj.qtdRegistros;
}

function cleanData(obj){
    for(let propName in obj){
        if(typeof obj[propName] === 'object'){
            obj[propName] = cleanData(obj[propName]);
        } else if(obj[propName] === null || obj[propName] === undefined || obj[propName] == '' || obj[propName] == {}){
            delete obj[propName];
        }
    }
    return obj;
}

export const initAnalytics = (contentId, siteId) => {
    if (typeof window === 'undefined') {
        global.window = {}
    }
    (function (window) {
        (function (w, d, s, e, t) {
            w.AnalyticsGJC = w.AnalyticsGJC || {}; w.AnalyticsGJC.filaAnalyticsGJC = w.AnalyticsGJC.filaAnalyticsGJC || [];
            e = d.createElement(s); e.type = 'text/java' + s; e.async = 'async'; e.src = 'https://s3-sa-east-1.amazonaws.com/analytics-gjc/analytics-gjc-min.js';
            t = d.getElementsByTagName(s)[0]; t.parentNode.insertBefore(e, t);
        })(window, document, 'script');

        var gjcJsonPageViewObj = {};

        window.AnalyticsGJC.filaAnalyticsGJC.push(['definirContentId', contentId]);
        window.AnalyticsGJC.filaAnalyticsGJC.push(['definirSiteId', siteId]);
    })(window);
}
