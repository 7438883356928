import styled from 'styled-components';
import InfiniteScroll from 'react-infinite-scroll-component';

export const AnuncianteContainer = styled.div`
    width: 100%;

    display: flex;
    flex-direction: column;
`;

export const Content = styled.div`
    width: 100%;
    max-width: 1100px;

    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;

    margin-left: auto;
    margin-right: auto;
`;

export const AnuncianteSearch = styled.div`
    width: 100%;

    background-color: #000;

    padding: 20px 0;

    display: flex;
    flex-direction: row;

    @media screen and (max-width: 768px){
        padding: 0;
    }
`;

export const AnuncianteResults = styled.div`
    width: calc(100% - 310px);

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;

    .infinite-scroll-component {
        overflow: initial !important;
    }

    @media screen and (max-width: 1220px){
        width: 100%;
        padding: 10px;
    }
`;

export const FabContainer = styled.div`
    position: fixed;
    bottom: 20px;
    right: 10px;

    .MuiCollapse-wrapperInner {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
    }
`;

export const AnuncioInfiniteScroll = styled(InfiniteScroll)`
    &&{
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
    }
`;