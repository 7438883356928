import styled from 'styled-components';

export const Separator = styled.div`
    width: 100%;

    border-bottom: 1px solid #4c0c5f;
    padding-bottom: 5px;
    margin-bottom: 10px;

    span{ font-size: 18px; font-weight: bold; }
`;