import React, { useState } from 'react';
import { CardContainer, CardDivider } from './card-styled';
import CardInfo from './card-info';
import CardPrice from './card-price';
import ImageGallery from 'react-image-gallery';
import { Hidden } from '@material-ui/core';
import { buildUrl } from '../../components/image-render/image-render';

const Card = ({ orientation, cardInfo }) => {

    const [info, setInfo] = useState({
        titulo: cardInfo.titulo,
        caracteristicas: cardInfo.caracteristicas || [],
        imagens: cardInfo.imagens || [],
        url: cardInfo.url,
        valor: cardInfo.valor,
        valorPromocional: cardInfo.valorPromocional,
        type: cardInfo.secao.value || ''
    })

    const cardImgs = info.imagens.map(item => { return { original: buildUrl({src: item.imageUrl, width: 250, height: 180}) } });

    const constructSubtitle = () => {

        const text = `
            ${cardInfo.subtitulo || ''} 
            ${cardInfo.tipoCombustivel ? cardInfo.tipoCombustivel.descricao : ''} 
            ${cardInfo.tipoCambio ? cardInfo.tipoCambio.descricao : ''}
        `
        return text;

    } 

    const customImg = (item) => {

        return(
            <img
                className="image-gallery-image"
                src={item.original}
                data-object-fit="cover"
            />
        )

    }

    return (
        <>

            <CardContainer orientation={orientation}>

                <ImageGallery
                    items={cardImgs}
                    renderItem={customImg.bind(this)}
                    showThumbnails={false}
                    showFullscreenButton={false}
                    showPlayButton={false}
                    showBullets={true}
                    disableKeyDown={true}
                />

                <CardInfo
                    titulo={info.titulo}
                    subtitulo={constructSubtitle()}
                    caracteristicas={info.caracteristicas}
                    orientation={orientation}
                    valor={{ valor: info.valor }}
                    valorPromocional={info.valorPromocional}
                    to={info.url}
                />

                <Hidden smDown>

                    {orientation !== 'vertical' &&
                        <CardDivider orientation={'vertical'} />
                    }

                    <CardPrice to={info.url} newPrice={info.valorPromocional} oldPrice={info.valor} orientation={orientation} />

                </Hidden>

            </CardContainer>

        </>
    )

}

export default Card;