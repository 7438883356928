import styled from 'styled-components';

export const TagList = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    margin-top: 5px;
`;

export const TagItem = styled.span`
    display: flex;
    align-items: center;
    color: #FFF;
    background-color: #f96302;
    padding: 3px 5px;
    margin-top: 5px;
    margin-left: 2px;
    margin-right: 2px;
`;

export const TagRemove = styled.div`
display: flex;
    line-height: 0;
    margin-left: 5px;
    cursor: pointer;

    svg{ font-size: 18px; }
`;