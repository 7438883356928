import styled from 'styled-components';

export const AnuncioContainer = styled.div`
    width: 100%;

    display: flex;
    flex-direction: column;
`;

export const AnuncioImgContainer = styled.div`
    width: 100%;
    background-color: #000000;
    padding: 20px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: 768px){
        padding: 0;
    }
`;

export const AnuncioImgContent = styled.div`
    width: 100%;
    max-width: 900px;

    display: flex;
    flex-direction: row;
    justify-content: center;

    @media screen and (max-width: 768px){
        flex-direction: column;
    }
`;

export const AnuncioTitleContainer = styled.div`
    width: 40%;

    display: flex;
    flex-direction: column;

    padding: 0 20px;

    @media screen and (max-width: 768px){
        width: 100%;
        margin-top: 20px;
        padding-bottom: 50px;
    }
`;

export const AnuncioTitle = styled.h1`
    font-size: 26px;
    font-weight: bold;
    color: #FFF;
    text-transform: uppercase;

    margin-top: 0;
    margin-bottom: 0;
`;

export const AnuncioSubtitle = styled.h2`
    font-size: 18px;
    color: #FFF;

    @media screen and (max-width: 768px){
        font-size: 16px;
    }
`;

export const PriceContainer = styled.div`
    width: 100%;

    color: #FFF;

    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @media screen and (max-width: 768px){
        flex-direction: row;
    }
`;

export const PriceTag = styled.div`
    width: 215px;
    height: 90px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    position: relative;
    
    span{
        color: #FFF;
        font-size: ${props => props.fontSize? props.fontSize: '31px'};
        font-weight: bold;
        position: relative;
        z-index: 99;
    }

    &:after{
        content: "";
        
        width: 212px;
        height: 90px;
        transform: rotate(-7deg);

        background-color: #4d0d60;
        box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.5);

        position: absolute;
        top: 0;
        left: 0;
    }
    
    &:before{
        content: "";
        
        width: 215px;
        height: 90px;
        transform: rotate(-12deg);
        
        background-color: #f96302;
        box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.5);
        
        position: absolute;
        top: 7px;
        left: 1px;
    }
`;

export const ContatoContainer = styled.div`
    width: 100%;
    margin-top: 60px;
`;

export const AnuncioInfo = styled.div`
    width: 100%;
    max-width: 900px;

    display: flex;
    flex-direction: column;

    padding: 20px;

    margin-left: auto;
    margin-right: auto;
`;

export const AnuncioContato = styled.div`
    width: 100%;    
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    margin-top: 20px;
    margin-bottom: 30px;

    @media screen and (max-width: 768px){
        flex-direction: column;
    }
`;

export const AnuncioContatoBtns = styled.div`
    display: flex;
    flex-direction:column;

    .phone{
        margin-bottom: 10px;
    }
`;

export const AnuncioSimpleText = styled.span`
    font-size: 18px;
    color: #8b8b8b;

    margin-bottom: 40px;
`;

export const AnuncioList = styled.ul`
    width: 100%;

    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;

    padding-left: 17px;
    margin-bottom: 40px;

    @media screen and (max-width: 768px){
        -webkit-column-count: 1;
        -moz-column-count: 1;
        column-count: 1;
    }
`;

export const AnuncioListItem = styled.li`
    color: #8b8b8b;
`;

export const AnuncioSlider = styled.div`
    width: 100%;

    @media screen and (max-width: 768px){
        width: calc(100% - 20px);
    }

    .slick-slide{
        width: 25%;
    }

    .slick-slider{
        max-height: 320px;
    }

    .slick-arrow{
        color: rgba(0, 0, 0, .5);

        &:hover{
            color: rgba(0, 0, 0, .8);
        }
    }
`;

export const AnuncioLoadingContainer = styled.div`
    width: 100%;
    background-color: #000;

    padding-top: 60px;

    position: absolute;
    top: 114px;
    bottom: 0;
    left: 0;
    right: 0;

    @media screen and (max-width: 768px){
        top: 60px;
    }
`;