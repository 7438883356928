import React, { useEffect, useState } from 'react';
import { HomeContainer, HomeSearch, HomeResults, Content } from './home-styled';
import { useSelector, useDispatch } from 'react-redux';
import ReactGA from 'react-ga';

// Actions
import { fetchLayout } from './../../actions';

// Components
import Topbar from '../../components/topbar/topbar';
import Banner from '../../components/banner/banner';
import Formulario from '../../components/formulario/formulario';
import Partnes from '../../components/partnes/partnes';
import Filter from '../../components/filter/filter';
import { Hidden, MenuItem } from '@material-ui/core';
import Card from '../../components/card/card';
import { BuscaResumo, BuscaTools, BuscaToolBtn, BuscaNotFound } from '../Busca/Busca-styled';
import { FETCH_SEARCH_OBJECT } from '../../actions/types';
import {veiculoResultsText} from '../../../helpers/stringHelper';
import Loading from '../../components/loading/loading';
import InfiniteScroll from "react-infinite-scroll-component";

import { initAnalytics, enviarPageView } from './../../components/analytics-gjc/analytics-gjc';

const vitrineType = process.env.VITRINE_TYPE.toLowerCase();

const Home = () => {
    const dispatch = useDispatch();

    // Carrega os dados do store
    const idFeirao = useSelector(state =>  state.layout.idFeirao);
    const layout = useSelector(state => state.layout.layout);
    const anuncios = useSelector(state => state.busca.buscaResults);
    const filtros = useSelector(state => state.busca.filtros);
    const searchObj = useSelector(state => state.busca.searchObj);

    // Variaveis da página
    const [anuncioList, setAnuncioList] = useState();
    const [loading, setLoading] = useState(true);
    const [firstLoad, setFirstLoad] = useState(true);
    const [hasMorePage, setHasMorePage] = useState(false);
    const [oldAnuncios, setOldAnuncios] = useState(anuncios);

    // Caso não tenha dados no store
    // Previne buscar 2x ao carregar a pagina
    useEffect(() => {      
        if (Object.keys(layout).length === 0) {
            dispatch(fetchLayout());
        }

        dispatch({ 
            type: FETCH_SEARCH_OBJECT, 
            payload: Object.assign(searchObj, { parceiroFeirao: null, pagina: 0 }) 
        });


        //  Analytics
        initAnalytics('vitrine_feirao_home', process.env.SITEID_ANALYTICS);
        enviarPageView({idFeirao: idFeirao});

        ReactGA.initialize(`${process.env.CODIGO_ANALYTICS}`);
        ReactGA.pageview(`${vitrineType + window.location.pathname}`);

    }, []);

    // Exibe o loading se carregar os anuncios do zero
    useEffect(() => {
        if(searchObj.pagina === 0){
            setLoading(true);
        }
    }, [searchObj]);

    // Remove a tela de loading 
    // e arquiva um historico de anuncio para futuras validações
    useEffect(() => {
        if(anuncios && anuncios !== oldAnuncios){
            setOldAnuncios(anuncios);
            setLoading(false);
            setFirstLoad(false);
        }
    }, [anuncios]);

    // Caso tenha mais oágians ele habilita a rolagem infinita
    useEffect(() => {
        if(filtros && filtros.qtdPaginas > filtros.paginaAtual + 1){
            setHasMorePage(true);
            
            setLoading(false);
            setFirstLoad(false);
        } else {
            setHasMorePage(false);

            setLoading(false);
            setFirstLoad(false);
        }
    }, [filtros]);

    // Evento de ordenação
    const handleSort = (e) => {
        const filter = Object.assign(searchObj, { ordenacao: e.target.value, pagina: 0 });
        dispatch({type: FETCH_SEARCH_OBJECT, payload: filter});
    }

    // Paginação
    // Rolagem Infinita
    const nextPage = () => {
        const pagina = searchObj.pagina || 0;
        const newFilter = Object.assign(searchObj, { pagina: pagina + 1 });

        dispatch({ type: FETCH_SEARCH_OBJECT, payload: newFilter });
    };

    return (
        <React.Fragment>

            <Topbar />

            {Object.keys(layout).length > 0 &&
                <HomeContainer>
                    {/* Banner do feirão */}
                    <Banner desktopUrl={layout.images_feirao_desktop} mobileUrl={layout.images_feirao_mobile} />

                    {/* Formulário - Leads  */}
                    <Formulario />

                    {/* Parceiros */}
                    <Partnes partnesList={layout.parceiros} />

                    {/* Container da busca */}
                    <HomeSearch>
                        <Content>
                            {/* Component de filtros */}
                            <Hidden smDown>
                                <Filter 
                                    defaultFilter={{ parceiroFeirao: null }}
                                />
                            </Hidden>

                            <HomeResults>
                                {/* Component de loading */}
                                {loading && <Loading /> } 

                                {/* Texto de resumo da busca */}
                                {!loading && anuncios && anuncios.length > 0 &&
                                    <BuscaResumo id="busca-resumo">
                                        {veiculoResultsText(searchObj, filtros)}
                                    </BuscaResumo>
                                }

                                {/* Filtro de ordenação */}                        
                                {!loading && filtros && filtros.ordenacao && anuncios && anuncios.length > 0 &&
                                    <Hidden smUp>
                                        <BuscaTools>
                                            <BuscaToolBtn
                                                variant="outlined"
                                                onChange={handleSort}
                                                value={searchObj.ordenacao || filtros.ordenacao[0].valor}>
                                                {filtros.ordenacao.map((item, index) =>                                             
                                                    <MenuItem 
                                                        key={index}
                                                        value={item.valor}
                                                    >{item.descricao}</MenuItem>
                                                )}
                                            </BuscaToolBtn>
                                        </BuscaTools>
                                    </Hidden>
                                }

                                {/* Rolagem infinita e resultado da busca  */}
                                {anuncios &&
                                    <InfiniteScroll
                                        dataLength={anuncios.length}
                                        next={() => nextPage()}
                                        hasMore={hasMorePage}
                                        loader={<Loading />}>
                                        {anuncios.map((card, index) => {
                                            return (
                                                <Card 
                                                    key={`card-${card.id}`} 
                                                    cardType={card.secao.value} 
                                                    orientation="horizontal" 
                                                    cardInfo={card} 
                                                />)
                                            })
                                        }
                                    </InfiniteScroll>
                                }

                                {/* Mensagem de busca não encontrada */}
                                {!loading && (!anuncios || anuncios.length === 0) &&
                                    <BuscaNotFound id="busca-resumo">
                                        Infelizmente não conseguimos localizar nenhum anúncio com a combinação de filtros escolhida
                                    </BuscaNotFound>
                                }                                              
                            </HomeResults>
                        </Content>
                    </HomeSearch>

                </HomeContainer>
            }
        </React.Fragment>
    )
}

export const loadData = ({ store }) => {
    return store.dispatch(fetchLayout());
};

export default Home;
