import React, { useState, useRef } from 'react';
import { Hidden, SvgIcon, Button } from '@material-ui/core';
import Modal from '../modal/modal';
import { ModalList, ModalItemList, ModalOptions, ModalBtn } from '../modal/modal-styled';
import Contatos from '../contatos/contatos';

// Icons
import WhatsappIcon from './../../../resources/svg/whatsapp.svg';
import RoomIcon from '@material-ui/icons/Room';
import PhoneIcon from '@material-ui/icons/Phone';

import {
    AnuncianteInfoContainer,
    AnuncianteLogo,
    FabBtn,
    Content,
    FabContainer,
    FloatBtn,
    FabOverlay
} from './anunciante-info-styled';

// Icons 
import PhoneIconTst from './../../../resources/svg/phone.svg';
import AddIcCallIcon from '@material-ui/icons/AddIcCall';
import { sendAnalytics } from '../analytics-gjc/analytics-gjc';
import { analyticsEvent } from '../analytics-gjc/analytics-events';
import { getIdFromURL } from '../../../helpers/stringHelper';
import { useParams, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

const PhoneList = ({phoneList}) => {
    return (
        <ModalList>
            {phoneList &&
                phoneList.map((phone, index) => {
                    return (
                        <ModalItemList key={`phone-${index}`}>
                            <a href={`tel:+${phone.numero}`}>
                                {phone.operadora.descricao ? phone.operadora.descricao + ' - ' : '' } {phone.numeroFormatado}
                            </a>
                        </ModalItemList>
                    )                    
                })
            }
        </ModalList>
    )
};

const MapAlert = ({ modalRef, localizacao }) => {
    return (
        <ModalOptions>
            <ModalBtn variant="contained" onClick={() => modalRef.current.toggleModal()}>Não</ModalBtn>
            <ModalBtn variant="contained" component="a" target="_blank" href={`https://www.google.com/maps/search/?api=1&query=${localizacao}`} onClick={() => modalRef.current.toggleModal()}>Sim</ModalBtn>
        </ModalOptions>
    );
}

const AnuncianteInfo = ({ id, idFeirao, name, logo, whatsapp, phoneList, localizacao }) => {
    const modalRef = useRef();
    const params = useParams();
    const location = useLocation();

    const [floatBtn, setFloatBtn] = useState('');
    const [modalTitle, setModalTitle] = useState('');
    const [modalContent, setModalContent] = useState('');

    return (
        <AnuncianteInfoContainer>
            <Modal ref={modalRef} titulo={modalTitle}>
                {modalContent === 'map' && <MapAlert modalRef={modalRef} localizacao={`${name + ',' || ''} ${localizacao.slice(0, 5)}-${localizacao.slice(-3)}`}/>}
                {modalContent === 'phone' && <PhoneList modalRef={modalRef} phoneList={phoneList} />}
            </Modal>

            <Content>
                <AnuncianteLogo src={logo} data-object-fit="contain"/>

                <Hidden smDown>
                    <Contatos
                        id={id}
                        idFeirao={idFeirao}
                        whatsapp={whatsapp}
                        msg={`Olá, vi seu anúncio no FEIRÃO DIGITAL DE VEÍCULOS CLASSI! Quero saber mais sobre o anúncio ${process.env.BASE_URL}${location.pathname}`}
                        showPhone={Boolean(phoneList)} 
                        showLocal={Boolean(localizacao)} 
                        showModal={({ titulo, content }) => {
                            modalRef.current.toggleModal(); 
                            setModalTitle(titulo); 
                            setModalContent(content);
                        }} />
                </Hidden>

                <Hidden smUp>
                    <FabContainer>
                        {floatBtn &&
                            <FabOverlay onClick={() => setFloatBtn(false)} />
                        }

                        {whatsapp &&
                            <FloatBtn 
                                className="whatsapp" 
                                in={floatBtn.toString()} 
                                component="a" 
                                target="_blank"                                 
                                href={`https://wa.me/55${whatsapp}?text=Olá, vi seu anúncio no FEIRÃO DIGITAL DE VEÍCULOS CLASSI! Quero saber mais sobre o anúncio ${process.env.BASE_URL}${location.pathname}`}
                                onClick={() => {
                                    sendAnalytics(analyticsEvent.VER_WHATSAPP, { idContrato: id, idFeirao: idFeirao });
                                  }}>
                                <SvgIcon className="icon" viewBox="0 0 512 512" component={WhatsappIcon} />
                            </FloatBtn>
                        }

                        {phoneList &&
                            <FloatBtn 
                                className="phone" 
                                in={floatBtn.toString()} 
                                onClick={() => { 
                                    modalRef.current.toggleModal(); 
                                    setModalTitle('Clique no número para ligar'); 
                                    setModalContent('phone');
                                    sendAnalytics(analyticsEvent.VER_TELEFONE, { idContrato: id, idFeirao: idFeirao });
                                }}>
                                <PhoneIcon className="icon" />
                            </FloatBtn>
                        }

                        {localizacao &&
                            <FloatBtn 
                                className="map" 
                                in={floatBtn.toString()} 
                                onClick={() => { 
                                    modalRef.current.toggleModal(); 
                                    setModalTitle('Deseja abrir o mapa em uma nova aba?'); 
                                    setModalContent('map');
                                    sendAnalytics(analyticsEvent.VER_ENDERECO, { idContrato: id, idFeirao: idFeirao });
                                }}>
                                <RoomIcon className="icon" />
                            </FloatBtn>
                        }

                        <FabBtn aria-label="Contatos" onClick={() => setFloatBtn(!floatBtn)} >
                            <AddIcCallIcon className="material-icon"/>
                        </FabBtn>
                    </FabContainer>
                </Hidden>
            </Content>
        </AnuncianteInfoContainer>
    )
}

export default AnuncianteInfo;