import styled from 'styled-components';
import { Modal, Paper, IconButton, darken, Button } from '@material-ui/core';

export const ModalContainer = styled(Modal)`
    &&{
        display: flex;
    }
`;

export const ModalPaper = styled(Paper)`
    &&{
        max-width: 80%;
        margin: auto;
        border-radius: 0;

        position: relative;
    }
`;

export const ModalTitle = styled.p`
    font-size: 18px;
    color: #FFF;
    background-color: #3140a8;

    text-align: center;
    padding: 25px;

    margin-top: 0;
    margin-bottom: 0;
`;

export const ModalBtn = styled(Button)`
    &&{
        width: calc(50% - 5px);
        font-weight: bold;
    }
`;

export const ModalContent = styled.div`
    background-color: #FFF;
`;

export const ModalList = styled.ul`
    width: 100%;

    padding: 0 5px;
    list-style: none;

    margin-top: 0;
    margin-bottom: 0;
`;

export const ModalItemList = styled.li`
    width: 100%;

    font-size: 18px;
    color: #000;
    text-align: center;
    padding: 20px 10px;

    border-bottom: 1px solid #979797;

    a{ color: #000; }

    &:last-child{
        border-bottom: 0;
    }
`;

export const ModalOptions = styled.div`
    width: 100%;

    padding: 15px;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

export const ModalClose = styled(IconButton)`
    &&{
        width: 30px;
        height: 30px;
        background-color: #FFF;
        padding: 0;
        
        position: absolute;
        top: -10px;
        right: -10px;

        &:hover{
            background-color: ${darken('#FFFFFF', 0.2)};
        }
    }
`;