import React, { useState, useEffect } from 'react';

import { CardTitulo, CardSubtitulo, CardInfoContainer, CardCaracteristicas, CardCaracteristicaItem } from './card-styled';
import { Hidden } from '@material-ui/core';
import CardPrice from './card-price';

const CardInfo = ({ titulo, subtitulo, caracteristicas, valor, valorPromocional, orientation, to }) => {

    const [infoList, setInfoList] = useState();

    useEffect(() => {
        if (caracteristicas) {
            const newInfoList = [];

            caracteristicas.map(item => {
                newInfoList.push(item.descricao);
            });

            setInfoList(newInfoList);
        }
    }, []);

    return (
        <CardInfoContainer to={to} orientation={orientation}>
            
            <CardTitulo orientation={orientation}>{titulo}</CardTitulo>
            <CardSubtitulo orientation={orientation}>{subtitulo}</CardSubtitulo>

            {orientation !== 'vertical' &&
                <Hidden smDown>
                    <CardCaracteristicas>
                        {infoList && infoList.map((item, index) => {
                            return (
                                <CardCaracteristicaItem key={`caracteristicas-${index}`}>{item}</CardCaracteristicaItem>
                            )
                        }).slice(0, 4)}
                    </CardCaracteristicas>
                </Hidden>
            }

            <Hidden smUp>
                <CardPrice to={to} newPrice={valorPromocional} oldPrice={valor.valor} />
            </Hidden>

        </CardInfoContainer>
    );
}

export default CardInfo;