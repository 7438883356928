import styled from 'styled-components';

export const ResumoContainer = styled.div`
    width: 80%;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    @media screen and (max-width: 768px){
       width: 100%; 
    }
`;

export const ResumoItem = styled.div`
    width: 33%;

    display: flex;
    flex-direction: column;

    margin-bottom: 20px;

    @media screen and (max-width: 768px){
        width: 50%;
    }
`;

export const ItemTitle = styled.p`
    font-size: 18px;
    font-weight: bold;

    margin-top: 0;
    margin-bottom: 0;
`; 

export const ItemDescription = styled.p`
    font-size: 18px;
    color: #8b8b8b;

    margin-top: 0;
    margin-bottom: 0;
`; 