import React, { useState, useEffect } from 'react';

import { SvgIcon } from '@material-ui/core';
import { ContatoContainer, ContatoBtn } from './contatos-styled';

// Icons
import WhatsappIcon from './../../../resources/svg/whatsapp.svg';
import RoomIcon from '@material-ui/icons/Room';
import PhoneIcon from '@material-ui/icons/Phone';
import { sendAnalytics } from '../analytics-gjc/analytics-gjc';
import { analyticsEvent } from '../analytics-gjc/analytics-events';
import { getIdFromURL } from '../../../helpers/stringHelper';
import { useParams } from 'react-router-dom';

const Contatos = ({ id, idFeirao, whatsapp, msg, showModal, showPhone, showLocal }) => {
    const params = useParams();

    return (
        <ContatoContainer>
            {whatsapp && 
                <ContatoBtn
                    className="whatsapp"
                    component="a"
                    href={`https://wa.me/55${whatsapp}?text=${msg}`}
                    target="_blank"
                    variant="contained"
                    onClick={() => {
                        sendAnalytics(analyticsEvent.VER_WHATSAPP, { idContrato: id, idFeirao: idFeirao })
                    }}
                    startIcon={<SvgIcon className="icon" viewBox="0 0 512 512" component={WhatsappIcon} />}
                >
                    Enviar mensagem
                </ContatoBtn>
            }

            {showPhone &&
                <ContatoBtn
                    className="phone"
                    variant="contained"
                    startIcon={<PhoneIcon className="icon" />}
                    onClick={() => { 
                        showModal({ titulo: 'Clique no número para ligar', content: 'phone' });
                        sendAnalytics(analyticsEvent.VER_TELEFONE, { idContrato: id, idFeirao: idFeirao });
                    }}
                >
                    Ligar para loja
                </ContatoBtn>
            }

            {showLocal &&
                <ContatoBtn
                    className="map"
                    onClick={() => { 
                        showModal({ titulo: 'Deseja abrir o mapa em uma nova aba?', content: 'map' }) 
                        sendAnalytics(analyticsEvent.VER_ENDERECO, { idContrato: id, idFeirao: idFeirao });
                    }}
                    variant="contained"
                    startIcon={<RoomIcon className="icon" />}
                >
                    Ver endereço
                </ContatoBtn>
            }
        </ContatoContainer>
    );

};

export default Contatos;