import React from 'react';
import { BannerContainer, BannerImg } from './banner-styled';
import { Hidden } from '@material-ui/core';

// Imgs
const Banner = ({ desktopUrl, mobileUrl }) => {
    return (
        <React.Fragment>
            <BannerContainer>
                <Hidden smDown>
                    <BannerImg src={desktopUrl} />
                </Hidden>
                <Hidden smUp>
                    <BannerImg src={mobileUrl} />
                </Hidden>
            </BannerContainer>
        </React.Fragment>
    );
}

export default Banner;