import React, { useState } from 'react';
import { GalleryContainer, GalleryGrid, MainImage, Thumbnails, MobileGallery } from './gallery-styled';
import ReactBnbGallery from 'react-bnb-gallery';

import ImageGallery from 'react-image-gallery';
import { Hidden } from '@material-ui/core';

import { buildUrl } from '../../components/image-render/image-render';

const Gallery = ({ imageList }) => {

    const [galleryOpened, setGalleryOpened] = useState(false);
    const [activePhotoIndex, setActivePhotoIndex] = useState(0);

    const mobileImages = () => {
        const images = imageList.map(img => { return { original: buildUrl({src: img.imageUrl, width: 500, height: 500}) }});
        return images;
    }

    const toggleGallery = (index) => {
        setGalleryOpened(!galleryOpened);
        setActivePhotoIndex(index || 0);
    };

    const setImagesToLightBox = () => {

        const list = imageList && imageList.map(img => {
            return { photo: buildUrl({src: img.imageUrl, width: 800, height: 600, resizeType:'fill'}), thumbnail: buildUrl({src: img.imageUrl, width: 100, height: 67}) }
        });

        return list;
    }

    return(
        <GalleryContainer>
            <Hidden smDown>
                <GalleryGrid>
                    <MainImage src={imageList ? buildUrl({src: imageList[0].imageUrl, width: 640, height: 480}): null} onClick={() => toggleGallery(0)} value={0}/>

                    {imageList && imageList.length >= 2 &&
                        imageList.map((img, index) => {
                            if(index <= 3){
                                return (
                                    <Thumbnails 
                                        key={`otherImage-${index}`}
                                        src={buildUrl({src: img.imageUrl, width: 130, height: 90})}
                                        onClick={ () => toggleGallery(index) }
                                        data-object-fit="cover"
                                    />
                                )
                            }
                        })
                    }
                </GalleryGrid>
            </Hidden>

            <Hidden smUp>
                <MobileGallery>
                    <ImageGallery
                        items={mobileImages()}
                        showThumbnails={false}
                        showFullscreenButton={false}
                        showPlayButton={false}
                        showBullets={true}
                        disableKeyDown={true}
                        lazyLoad={true}
                    />
                </MobileGallery>
            </Hidden>

            <ReactBnbGallery
                phrases={{showPhotoList: 'Mostrar lista', hidePhotoList: 'Esconder lista'}}
                show={ galleryOpened }
                photos={ setImagesToLightBox() }
                onClose={ toggleGallery }
                activePhotoIndex={ activePhotoIndex }
            />
        </GalleryContainer>
    );
};

export default Gallery;